import { createSlice } from '@reduxjs/toolkit';
import * as Constants from 'common/Constants';
import { CARRIER_CODE } from 'common/AppConstant';
import { bestCodes, POLICY_CODE } from 'common/Constants';
import * as API from './service';
import {
  updateClaimWeightHelper, updateHospitalIndemnityPayoutHelper,
  updateCriticalIllnessPayoutHelper, updateAccidentPayoutHelper,
  updateHI, updateAC, updateCI,
} from '../common/reducerHelperFunctions';

const initialCounts = {
  employeeCount: 0,
  spouseCount: 0,
  childrenCount: 0,
  totalCount: 0,
};

// convert gender_count/ageband_count to gender_count/total_count
const getAgeDistribution = (ageScenarios) => ageScenarios?.map((ageScenario) => {
  const newAgeScenario = { ...ageScenario };
  newAgeScenario.malePercentageOfBenchmark = ((newAgeScenario.malePercentageOfBenchmark
    * newAgeScenario.percentageOfBenchmark) / 100).toFixed(2);
  newAgeScenario.femalePercentageOfBenchmark = ((newAgeScenario.femalePercentageOfBenchmark
    * newAgeScenario.percentageOfBenchmark) / 100).toFixed(2);
  newAgeScenario.otherPercentageOfBenchmark = ((newAgeScenario.otherPercentageOfBenchmark
      * newAgeScenario.percentageOfBenchmark) / 100).toFixed(2);
  newAgeScenario.malePercentageOfCensus = ((newAgeScenario.malePercentageOfCensus
    * newAgeScenario.percentageOfCensus) / 100).toFixed(2);
  newAgeScenario.femalePercentageOfCensus = ((newAgeScenario.femalePercentageOfCensus
    * newAgeScenario.percentageOfCensus) / 100).toFixed(2);
  newAgeScenario.otherPercentageOfCensus = ((newAgeScenario.otherPercentageOfCensus
      * newAgeScenario.percentageOfCensus) / 100).toFixed(2);
  return newAgeScenario;
});

const getMappedBenefit2 = (benefit2) => {
  const mappedBenefit2 = {};
  Object.keys(benefit2).forEach((benefitKey) => {
    mappedBenefit2[`${benefitKey}2`] = benefit2[benefitKey];
  });
  return mappedBenefit2;
};

export const scenarioSlice = createSlice({
  name: 'scenario',
  initialState: {
    tempFileName: null,
    tempS3FilePath: null,
    searchRefreshFlag: false,
    processStatus: null,
    scenarios: [],
    scenario: null,
    genderDistribution: [],
    ageDistribution: [],
    relationshipDistribution: [],
    scenarioId: 0,
    industryList: [],
    regionList: [],
    scenarioSection: 0,
    demographicFetchFlag: true,
    claimFetchFlag: true,
    claimHoldFlag: false,
    claimWeightUpdateFlag: false,
    claim: {},
    claimWeight: { HI: 1, CI: 1, AC: 1 },
    claimWeightEnroll15: { HI: 1, CI: 1, AC: 1 },
    claimWeightCom15: { HI: 1, CI: 1, AC: 1 },
    hospitalIndemnityFlag: true,
    criticalIllnessFlag: true,
    accidentFlag: true,
    totalPayout: {
      HI: {}, CI: {}, AC: {},
    },
    totalCount: {
      HI: {}, CI: {}, AC: {},
    },
    HIPayoutDetail: [],
    hospitalIndemnityPayoutFlag: false,
    hospitalIndemnityObject: {},
    participationPercentages: {
      HI: {
        participationPerc1: 100,
        participationPerc2: 0,
      },
      AC: {
        participationPerc1: 100,
        participationPerc2: 0,
      },
    },
    CIPayoutDetail: [],
    criticalIllnessPayoutFlag: false,
    criticalIllnessObject: {},
    ACPayoutDetail: [],
    accidentPayoutFlag: false,
    accidentObject: {},
    coveragePage: 0,
    policyAdjustments: {},
    enrollCondConsts: [],
    HICategoryCode: {},
    CICategoryCode: {},
    ACCategoryCode: {},
    policyInitialList: [],
    policyModuleList: [],
    HIModuleList: [],
    CIModuleList: [],
    ACModuleList: [],
    customTexts: [],
    customTextRefreshFlag: false,
    completedFlag: false,
    isModified: false,
  },
  reducers: {
    resetScenario(state) {
      state.scenario = {
        name: '',
        region: '',
        numOfMembers: '',
        industry: '',
        medicalPmpm: '',
        cancerEvents: '',
        diabetic: '',
        hypertension: '',
        censusFile: null,
        censusFileName: '',
        censusFilePath: '',
        processStatus: null,
        privateScenario: false,
        createdBy: null,
      };
      state.scenarioId = 0;
      state.processStatus = null;
      state.privateScenario = false;
      state.censusFileName = null;
      state.tempS3FilePath = null;
      state.customTexts = [];
      state.completedFlag = false;
    },

    updateFormState(state, action) {
      state.isModified = action.payload;
    },

    updateScenarioSection(state, action) {
      state.scenarioSection = action.payload;
    },
    resetCensusFile(state) {
      state.scenario.censusFileName = '';
      state.scenario.censusFilePath = '';
    },
    updateCoveragePage(state, action) {
      state.coveragePage = action.payload;
    },
    updateHospitalIndemnityPayout(state) {
      updateHospitalIndemnityPayoutHelper(state);
    },

    updateCriticalIllnessPayout(state) {
      updateCriticalIllnessPayoutHelper(state);
    },

    updateAccidentPayout(state) {
      updateAccidentPayoutHelper(state);
    },

    updateComparePlan(state, action) {
      const carrierCode = localStorage.getItem(CARRIER_CODE);
      const goodHIObject = JSON.parse(JSON.stringify(state.hospitalIndemnityObject));
      const goodCIObject = JSON.parse(JSON.stringify(state.criticalIllnessObject));
      const goodACObject = JSON.parse(JSON.stringify(state.accidentObject));
      const bestHIObject = JSON.parse(JSON.stringify(state.hospitalIndemnityObject));
      const bestCIObject = JSON.parse(JSON.stringify(state.criticalIllnessObject));
      const bestACObject = JSON.parse(JSON.stringify(state.accidentObject));
      Object.values(state.HIPayoutDetail).forEach((item) => {
        if (action.payload.HI.indexOf(item.name) < 0
          || action.payload.HI.indexOf(item.name) >= 4) {
          goodHIObject[item.name] = 0;
        }
        if (action.payload.HI.indexOf(item.name) >= 0
          && bestCodes[carrierCode].HI.includes(item.name)) {
          bestHIObject[item.name] = Constants.getBestBenefitPrice(bestHIObject[item.name]);
        }
      });
      Object.values(state.CIPayoutDetail).forEach((item) => {
        if ('code' in item && item.code !== '' && item.code !== null) {
          if (action.payload.CI.indexOf(item.name) < 0
            || action.payload.CI.indexOf(item.name) >= 8) {
            goodCIObject[item.name] = 0;
          }
          if (action.payload.CI.indexOf(item.name) >= 0
            && bestCodes[carrierCode].CI.includes(item.name)) {
            bestCIObject[item.name] = Constants.getBestBenefitPrice(bestCIObject[item.name]);
          }
        }
      });
      Object.values(state.ACPayoutDetail).forEach((item) => {
        if (action.payload.AC.indexOf(item.name) < 0
          || action.payload.AC.indexOf(item.name) >= 8) {
          goodACObject[item.name] = 0;
        }
        if (action.payload.AC.indexOf(item.name) >= 0
          && bestCodes[carrierCode].AC.includes(item.name)) {
          bestACObject[item.name] = Constants.getBestBenefitPrice(bestACObject[item.name]);
        }
      });
      updateHI(state, goodHIObject, state.claimWeight, 'countGood', 'payoutGood');
      updateHI(state, bestHIObject, state.claimWeight, 'countBest', 'payoutBest');
      updateCI(state, goodCIObject, state.claimWeight, 'countGood', 'payoutGood');
      updateCI(state, bestCIObject, state.claimWeight, 'countBest', 'payoutBest');
      updateAC(state, goodACObject, state.claimWeight, 'countGood', 'payoutGood');
      updateAC(state, bestACObject, state.claimWeight, 'countBest', 'payoutBest');
    },

    updateClaimWeight: (state) => {
      updateClaimWeightHelper(state);
    },

    updateCategoryCode: (state) => {
      const tempHI = {};
      const tempHIModule = [];
      state.HIPayoutDetail.forEach((item) => {
        if (item.carrierCodes.includes(localStorage.getItem(CARRIER_CODE))) {
          const key = item.module.code;
          if (key in tempHI) {
            tempHI[key].push(item);
          } else {
            tempHI[key] = [item];
            tempHIModule.push(item.module);
          }
        }
      });
      state.HICategoryCode = tempHI;
      state.HIModuleList = tempHIModule;

      const tempCI = {};
      const tempCIModule = [];
      state.CIPayoutDetail.forEach((item) => {
        if (item.carrierCodes.includes(localStorage.getItem(CARRIER_CODE))) {
          const key = item.module.code;
          if (key in tempCI) {
            tempCI[key].push(item);
          } else {
            tempCI[key] = [item];
            tempCIModule.push(item.module);
          }
        }
      });
      state.CICategoryCode = tempCI;
      state.CIModuleList = tempCIModule;

      const tempAC = {};
      const tempACModule = [];
      state.ACPayoutDetail.forEach((item) => {
        if (item.carrierCodes.includes(localStorage.getItem(CARRIER_CODE))) {
          const key = item.module.code;
          if (key in tempAC) {
            tempAC[key].push(item);
          } else {
            tempAC[key] = [item];
            tempACModule.push(item.module);
          }
        }
      });
      state.ACCategoryCode = tempAC;
      state.ACModuleList = tempACModule;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(API.saveScenario.fulfilled, (state, action) => {
      state.scenario = { ...action.payload, numOfMembers: `${action.payload?.numOfMembers}` };
      state.scenarioId = action.payload.id;
      state.processStatus = action.payload.status;
      state.privateScenario = action.payload.privateScenario;
      state.tempS3FilePath = null;
      state.tempFileName = null;
      state.completedFlag = true;
      state.isModified = false;
    });

    builder.addCase(API.searchScenario.fulfilled, (state, action) => {
      state.scenarios = action.payload;
    });

    builder.addCase(API.saveTempFile.fulfilled, (state, action) => {
      state.tempFileName = action.payload.censusFileName;
      state.tempS3FilePath = action.payload.tempS3FilePath;
    });

    builder.addCase(API.getById.fulfilled, (state, action) => {
      state.scenario = { ...action.payload, numOfMembers: `${action.payload?.numOfMembers}` };
      state.scenarioId = action.payload.id;
      state.processStatus = action.payload.status;
      state.privateScenario = action.payload.privateScenario;
      state.demographicFetchFlag = !state.demographicFetchFlag;
      state.claimFetchFlag = !state.claimFetchFlag;
      state.tempS3FilePath = null;
      state.tempFileName = null;
    });

    builder.addCase(API.getStatusById.fulfilled, (state, action) => {
      state.processStatus = action.payload;
    });

    builder.addCase(API.getDemographicById.fulfilled, (state, action) => {
      if (action.payload) {
        state.ageDistribution = getAgeDistribution(action.payload.ageScenarios);
        state.genderDistribution = action.payload.genderScenarios;
        state.relationshipDistribution = action.payload.relationshipScenarios;
      }
    });

    builder.addCase(API.getIndustryList.fulfilled, (state, action) => {
      state.industryList = action.payload.map((obj) => obj.displayName);
    });

    builder.addCase(API.getRegionList.fulfilled, (state, action) => {
      state.regionList = action.payload.map((obj) => obj.displayName);
    });

    builder.addCase(API.deleteScenario.fulfilled, (state) => {
      state.searchRefreshFlag = !state.searchRefreshFlag;
    });

    builder.addCase(API.getClaimById.fulfilled, (state, action) => {
      state.claim = action.payload.reduce(
        (obj, item) => Object.assign(obj, {
          [item.policyCode]: {
            employeeCount: Math.round(item.employeeCount),
            spouseCount: Math.round(item.spouseCount),
            childrenCount: Math.round(item.childrenCount),
            totalCount: Math.round(item.employeeCount)
              + Math.round(item.spouseCount)
              + Math.round(item.childrenCount),
          },
        }),
        {
          acc_er_urgent: { ...initialCounts },
          acc_er_room: { ...initialCounts },
          acc_ambulance_ground: { ...initialCounts },
          acc_ambulance_air: { ...initialCounts },
        },
      );
      const keys = ['employeeCount', 'spouseCount', 'childrenCount', 'totalCount'];
      keys.forEach((key) => {
        if (state.claim.esrd && state.claim.esrd[key] && state.claim.major_organ_transplant
          && state.claim.major_organ_transplant[key]) {
          state.claim.esrd[key] += state.claim.major_organ_transplant[key];
        }
        if (state.claim.acc_er_urgent && state.claim.acc_er && state.claim.acc_er[key]) {
          state.claim.acc_er_urgent[key] = Math.round(state.claim.acc_er[key] * 0.19);
        }
        if (state.claim.acc_er_room && state.claim.acc_er && state.claim.acc_er[key]) {
          state.claim.acc_er_room[key] = Math.round(state.claim.acc_er[key] * 0.81);
        }
        if (state.claim.acc_ambulance_ground && state.claim.acc_ambulance
          && state.claim.acc_ambulance[key]) {
          state.claim.acc_ambulance_ground[key] = Math.round(state.claim.acc_ambulance[key] * 0.94);
        }
        if (state.claim.acc_ambulance_air && state.claim.acc_ambulance
          && state.claim.acc_ambulance[key]) {
          state.claim.acc_ambulance_air[key] = Math.round(state.claim.acc_ambulance[key] * 0.06);
        }
      });
      state.claimHoldFlag = !state.claimHoldFlag;
    });

    builder.addCase(API.getHospitalIndemnityById.fulfilled, (state, { payload }) => {
      const mappedBenefit2 = getMappedBenefit2(payload?.benefit2);
      state.hospitalIndemnityObject = { ...payload?.benefit1, ...mappedBenefit2 };
      state.hospitalIndemnityPayoutFlag = !state.hospitalIndemnityPayoutFlag;
    });

    builder.addCase(API.getCriticalIllnessById.fulfilled, (state, { payload }) => {
      const mappedBenefit2 = getMappedBenefit2(payload?.benefit2);
      state.criticalIllnessObject = { ...payload?.benefit1, ...mappedBenefit2 };
      state.criticalIllnessPayoutFlag = !state.criticalIllnessPayoutFlag;
    });

    builder.addCase(API.getAccidentById.fulfilled, (state, { payload }) => {
      const mappedBenefit2 = getMappedBenefit2(payload?.benefit2);
      state.accidentObject = { ...payload?.benefit1, ...mappedBenefit2 };
      state.accidentPayoutFlag = !state.accidentPayoutFlag;
    });

    builder.addCase(API.saveHospitalIndemnity.fulfilled, (state, { payload }) => {
      const mappedBenefit2 = getMappedBenefit2(payload?.benefit2);
      state.hospitalIndemnityObject = { ...payload?.benefit1, ...mappedBenefit2 };
      state.hospitalIndemnityPayoutFlag = !state.hospitalIndemnityPayoutFlag;
    });

    builder.addCase(API.saveCriticalIllness.fulfilled, (state, { payload }) => {
      const mappedBenefit2 = getMappedBenefit2(payload?.benefit2);
      state.criticalIllnessObject = { ...payload?.benefit1, ...mappedBenefit2 };
      state.criticalIllnessPayoutFlag = !state.criticalIllnessPayoutFlag;
    });

    builder.addCase(API.saveAccident.fulfilled, (state, { payload }) => {
      const mappedBenefit2 = getMappedBenefit2(payload?.benefit2);
      state.accidentObject = { ...payload?.benefit1, ...mappedBenefit2 };
      state.accidentPayoutFlag = !state.accidentPayoutFlag;
    });

    builder.addCase(API.getPolicyAdjustmentsById.fulfilled, (state, action) => {
      state.policyAdjustments = action.payload;
      state.claimWeightUpdateFlag = !state.claimWeightUpdateFlag;
    });

    builder.addCase(API.savePolicyAdjustments.fulfilled, (state, action) => {
      state.policyAdjustments = action.payload;
      state.claimWeightUpdateFlag = !state.claimWeightUpdateFlag;
    });

    builder.addCase(API.getEnrollCondConsts.fulfilled, (state, action) => {
      state.enrollCondConsts = action.payload;
      state.claimWeightUpdateFlag = !state.claimWeightUpdateFlag;
    });

    builder.addCase(API.getPolicyInfo.fulfilled, (state, action) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        const [policyInfoMapList, policyModuleList] = action.payload;
        const policyInfoList = policyInfoMapList.map(
          ({ module, policyInfo }) => ({ ...policyInfo, module }),
        );
        state.HIPayoutDetail = policyInfoList.filter((x) => x.policyCode === POLICY_CODE.HI);
        state.CIPayoutDetail = policyInfoList.filter((x) => x.policyCode === POLICY_CODE.CI);
        state.ACPayoutDetail = policyInfoList.filter((x) => x.policyCode === POLICY_CODE.AC);
        state.policyModuleList = policyModuleList;
      }
    });

    builder.addCase(API.getCustomTextByScenarioId.fulfilled, (state, action) => {
      state.customTexts = action.payload;
    });

    builder.addCase(API.saveCustomText.fulfilled, (state) => {
      state.customTextRefreshFlag = !state.customTextRefreshFlag;
    });

    builder.addCase(API.deleteCustomTextById.fulfilled, (state) => {
      state.customTextRefreshFlag = !state.customTextRefreshFlag;
    });

    builder.addCase(API.getParticipationPerc.fulfilled, (state, { payload }) => {
      const rate1 = payload?.rate1 || 0;
      if (payload?.product === 'HI') {
        state.participationPercentages.HI.participationPerc1 = rate1;
        state.participationPercentages.HI.participationPerc2 = 100 - rate1;
      } else {
        state.participationPercentages.AC.participationPerc1 = rate1;
        state.participationPercentages.AC.participationPerc2 = 100 - rate1;
      }
    });

    builder.addCase(API.saveParticipationPerc.fulfilled, (state, { payload }) => {
      const rate1 = payload?.rate1 || 0;
      if (payload?.product === 'HI') {
        state.participationPercentages.HI.participationPerc1 = rate1;
        state.participationPercentages.HI.participationPerc2 = 100 - rate1;
      } else {
        state.participationPercentages.AC.participationPerc1 = rate1;
        state.participationPercentages.AC.participationPerc2 = 100 - rate1;
      }
    });
  },
});

export const {
  resetScenario, updateScenarioSection, resetCensusFile,
  updateHospitalIndemnityPayout, updateCriticalIllnessPayout,
  updateAccidentPayout, updateCoveragePage, updateClaimWeight,
  updateCategoryCode, updateComparePlan, updateFormState,
} = scenarioSlice.actions;
export default scenarioSlice.reducer;
