import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { MAIN_HOST } from 'common/AppConstant';

export const saveScenario = createAsyncThunk(
  'scenario',
  async (dto) => {
    const response = await axios.post(`${MAIN_HOST}/scenario`, dto);
    return response.data;
  },
);

export const saveTempFile = createAsyncThunk(
  'saveTempFile',
  async (file) => {
    const formData = new FormData();
    formData.append('censusFile', file);
    const response = await axios.post(`${MAIN_HOST}/S3/census/temp-file`, formData);
    return response.data;
  },
);

export const deleteScenario = createAsyncThunk(
  'deleteById',
  async (id) => {
    const response = await axios.delete(`${MAIN_HOST}/scenario/delete/id/${id}`);

    return response.data;
  },
);

export const searchScenario = createAsyncThunk(
  'search',
  async (dto) => {
    const response = await axios.post(`${MAIN_HOST}/scenario/search`, dto);
    return response.data;
  },
);

export const getById = createAsyncThunk(
  'getById',
  async (id) => {
    const response = await axios.get(`${MAIN_HOST}/scenario/id/${id}`);

    return response.data;
  },
);

export const getStatusById = createAsyncThunk(
  'getStatusById',
  async (id) => {
    const response = await axios.get(`${MAIN_HOST}/scenario/status/id/${id}`);
    return response.data;
  },
);

export const getIndustryList = createAsyncThunk(
  'getIndustryList',
  async () => {
    const response = await axios.get(`${MAIN_HOST}/scenario/scenario-information/industry-list`);

    return response.data;
  },
);

export const getRegionList = createAsyncThunk(
  'getRegionList',
  async () => {
    const response = await axios.get(`${MAIN_HOST}/scenario/scenario-information/region-list`);

    return response.data;
  },
);

export const getDemographicById = createAsyncThunk(
  'getDemographicById',
  async (id) => {
    const response = await axios.get(`${MAIN_HOST}/scenario/demographic/id/${id}`);

    return response.data;
  },
);

export const getClaimById = createAsyncThunk(
  'getClaimById',
  async (id) => {
    const response = await axios.get(`${MAIN_HOST}/scenario/policy-result/id/${id}`);

    return response.data;
  },
);

export const getHospitalIndemnityById = createAsyncThunk(
  'getHospitalIndemnityById',
  async (id) => {
    const response = await axios.get(`${MAIN_HOST}/scenario/policy/hospital-indemnity/id/${id}`);
    return response.data;
  },
);

export const saveHospitalIndemnity = createAsyncThunk(
  'saveHospitalIndemnity',
  async (data) => {
    const response = await axios.post(`${MAIN_HOST}/scenario/policy/hospital-indemnity`, data);
    return response.data;
  },
);

export const getCriticalIllnessById = createAsyncThunk(
  'getCriticalIllnessById',
  async (id) => {
    const response = await axios.get(`${MAIN_HOST}/scenario/policy/critical-illness/id/${id}`);
    return response.data;
  },
);

export const saveCriticalIllness = createAsyncThunk(
  'saveCriticalIllness',
  async (data) => {
    const response = await axios.post(`${MAIN_HOST}/scenario/policy/critical-illness`, data);
    return response.data;
  },
);

export const getAccidentById = createAsyncThunk(
  'getAccidentById',
  async (id) => {
    const response = await axios.get(`${MAIN_HOST}/scenario/policy/accident/id/${id}`);
    return response.data;
  },
);

export const saveAccident = createAsyncThunk(
  'saveAccident',
  async (data) => {
    const response = await axios.post(`${MAIN_HOST}/scenario/policy/accident`, data);
    return response.data;
  },
);

export const getPolicyAdjustmentsById = createAsyncThunk(
  'getPolicyAdjustmentsById',
  async (id) => {
    const response = await axios.get(`${MAIN_HOST}/scenario/policy/adjustments/id/${id}`);
    return response.data;
  },
);

export const savePolicyAdjustments = createAsyncThunk(
  'savePolicyAdjustments',
  async (data) => {
    const response = await axios.post(`${MAIN_HOST}/scenario/policy/adjustments`, data);
    return response.data;
  },
);

export const getEnrollCondConsts = createAsyncThunk(
  'getEnrollCondConsts',
  async () => {
    const response = await axios.get(`${MAIN_HOST}/scenario/policy/enroll-const`);
    return response.data;
  },
);

export const getPolicyInfo = createAsyncThunk(
  'getPolicyInfo',
  async () => {
    const raw = await axios.all([
      axios.get(`${MAIN_HOST}/scenario/policy/info/all`),
      axios.get(`${MAIN_HOST}/scenario/policy/module/all`),
    ]);
    const response = [];
    response.push(raw[0].data);
    response.push(raw[1].data);
    return response;
  },
);

export const getCustomTextByScenarioId = createAsyncThunk(
  'getCustomTextByScenarioId',
  async (id) => {
    const response = await axios.get(`${MAIN_HOST}/scenario/output/message/id/${id}`);
    return response.data;
  },
);

export const saveCustomText = createAsyncThunk(
  'saveCustomText',
  async (data) => {
    const response = await axios.post(`${MAIN_HOST}/scenario/output/message/save`, data);
    return response.data;
  },
);

export const deleteCustomTextById = createAsyncThunk(
  'deleteCustomTextById',
  async (id) => {
    const response = await axios.delete(`${MAIN_HOST}/scenario/output/message/id/${id}`);
    return response.data;
  },
);

export const getParticipationPerc = createAsyncThunk(
  'getParticipationPerc',
  async ({ scenarioId, policyCode }) => {
    const response = await axios.get(`${MAIN_HOST}/scenario/policy/participation-rate/id/${scenarioId}/product/${policyCode}`);
    return response.data;
  },
);

export const saveParticipationPerc = createAsyncThunk(
  'saveParticipationPerc',
  async (reqPayload) => {
    const response = await axios.post(`${MAIN_HOST}/scenario/policy/participation-rate`, reqPayload);
    return response.data;
  },
);

export const sendErrorEmail = createAsyncThunk(
  'sendErrorEmail',
  async (id) => {
    await axios.post(`${MAIN_HOST}/scenario/error-email/id/${id}`);
  },
);

export const cancelErrorEmail = createAsyncThunk(
  'cancelErrorEmail',
  async (id) => {
    await axios.post(`${MAIN_HOST}/scenario/cancel-error-email/id/${id}`);
  },
);

export default saveScenario;
