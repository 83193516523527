import React from 'react';
import { useSelector } from 'react-redux';

export default function Admin() {
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  return (
    <>
      {isAdmin}
      hello
    </>
  );
}
