import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Typography } from '@mui/material';
import * as Constants from 'common/Constants';
import { useSelector } from 'react-redux';
import store from 'app/store';
import CancelScenarioDialog from './cancelScenarioDialog';
import { updateScenarioSection, updateCoveragePage, updateFormState } from '../state/reducer';
import CensusFileReminderDialog from './censusFileReminder';

export default function VerticalStepper() {
  const currPage = useSelector((state) => state.scenario.scenarioSection);
  const currCoveragePage = useSelector((state) => state.scenario.coveragePage);
  const scenarioId = useSelector((state) => state.scenario.scenarioId);
  const processStatus = useSelector((state) => state.scenario.processStatus);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [cancelDialogOpen, setCancelDialogOpen] = React.useState(false);
  const formStateValue = useSelector((state) => state.scenario.isModified);
  const [dialogIndex, setDialogIndex] = React.useState(null);
  const [dialogSubindex, setDialogSubindex] = React.useState(null);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const redirect = (toPage) => {
    if (toPage !== currPage) {
      store.dispatch(updateScenarioSection(toPage));
    }
  };

  const cancelScenarioClose = () => {
    setCancelDialogOpen(false);
  };

  const handleStepClick = (index, screen) => {
    if (processStatus === Constants.STATUS_CODE.PYTHON_INVALID && index >= 1) {
      handleDialogOpen();
      return;
    }
    if ((screen || !formStateValue) && scenarioId > 0
    && processStatus === Constants.STATUS_CODE.SUCCESS) {
      redirect(index);
      store.dispatch(updateFormState(false));
    }
    if (formStateValue && scenarioId && scenarioId > 0) {
      setCancelDialogOpen(formStateValue);
      setDialogIndex(index);
    }
  };

  const handleCoverageStepClick = (index, subindex, screen) => {
    if (processStatus === Constants.STATUS_CODE.PYTHON_INVALID) {
      handleDialogOpen();
    }
    if ((screen || !formStateValue) && scenarioId && scenarioId > 0
        && processStatus === Constants.STATUS_CODE.SUCCESS) {
      store.dispatch(updateCoveragePage(subindex));
      redirect(index);
      store.dispatch(updateFormState(false));
    }
    if (formStateValue && scenarioId && scenarioId > 0) {
      setCancelDialogOpen(formStateValue);
      setDialogIndex(index);
      setDialogSubindex(subindex);
    }
  };

  const carrierSteps = React.useMemo(() => Constants.steps(), []);

  return (
    <Box>
      <Stepper
        nonLinear
        orientation="vertical"
        connector={null}
        activeStep={currPage}
      >
        {carrierSteps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>
              <Typography
                fontWeight={currPage === index ? 'bold' : 'normal'}
                color={currPage === index ? Constants.PRIMARY_DARK : 'black'}
                onClick={() => handleStepClick(index)}
                sx={{ cursor: 'pointer' }}
              >
                {step.label}
              </Typography>
            </StepLabel>
            {step.sublabel ? step.sublabel.map((substep, subindex) => (
              <Typography
                fontWeight={currPage === index && currCoveragePage === subindex ? 'bold' : 'normal'}
                color={currPage === index && currCoveragePage === subindex ? Constants.PRIMARY_DARK : 'black'}
                onClick={() => handleCoverageStepClick(index, subindex)}
                sx={{ cursor: 'pointer', marginLeft: '32px' }}
              >
                {substep}
              </Typography>
            )) : null}
          </Step>
        ))}
      </Stepper>
      <CensusFileReminderDialog
        content={Constants.CENSUS_FILE_REMINDER_CONTENT.FILE_INVALID}
        open={dialogOpen}
        onClose={handleDialogClose}
      />
      <CancelScenarioDialog
        formState={formStateValue}
        isOpen={cancelDialogOpen}
        onClose={() => cancelScenarioClose(false)}
        handleStepClick={handleStepClick}
        handleCoverageStepClick={handleCoverageStepClick}
        index={dialogIndex}
        subindex={dialogSubindex}
      />
    </Box>
  );
}
