import {
  Grid, Card, Typography, Box, TextField, Button, InputAdornment, IconButton,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import React from 'react';
import { grey } from '@mui/material/colors';
import { Controller, useForm } from 'react-hook-form';
import store from 'app/store';
import { THIS_FIELD_IS_REQUIRED } from 'common/ErrorConstants';
import { passwordChange } from './state/service';

export default function PasswordChange({ username, password }) {
  const { control, handleSubmit, getValues } = useForm(
    { defaultValues: { passwordPrev: password } },
  );

  const [showPasswordFlag, setShowPasswordFlag] = React.useState({
    prev: false,
    new: false,
    again: false,
  });

  const handleClickShowPassword = (key) => {
    setShowPasswordFlag({
      ...showPasswordFlag,
      [key]: !showPasswordFlag[key],
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const changePassword = (data) => {
    store.dispatch(passwordChange({ ...data, username }));
  };

  return (
    <Box
      margin={-1}
      sx={{
        height: '100vh',
        width: '100vw',
        backgroundColor: grey[200],
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Grid container padding={2}>
        <Grid item xs={4.5} />
        <Grid item xs={3} marginTop={20}>
          <Card>
            <Grid
              container
              spacing={2}
              direction="column"
            >
              <Grid item xs={12}>
                <Typography variant="h5" color="black">
                  First-time Login Password Change
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" color={grey[500]}>
                  Glad to see you here! Please change your password before you first time login.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="passwordPrev"
                  control={control}
                  rules={{
                    required: THIS_FIELD_IS_REQUIRED,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      id="input-password-prev"
                      label="Previous Password"
                      {...field}
                      fullWidth
                      error={error}
                      helperText={error ? error.message : null}
                      type={showPasswordFlag.prev ? 'text' : 'password'}
                      InputProps={{
                        endAdornment:
  <InputAdornment position="end">
    <IconButton
      aria-label="previous password visibility"
      onClick={() => handleClickShowPassword('prev')}
      onMouseDown={handleMouseDownPassword}
      edge="end"
    >
      {showPasswordFlag.prev ? <VisibilityOff /> : <Visibility />}
    </IconButton>
  </InputAdornment>,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="passwordNew"
                  control={control}
                  rules={{
                    required: THIS_FIELD_IS_REQUIRED,
                    validate: (value) => {
                      if (!value.match('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*?&+-_.])[A-Za-z0-9@$!%*?&+-_.]{8,}$')) {
                        return (
                          <div>
                            <div>The password must satisfies below conditions:</div>
                            <div>1. Have at least eight characters;</div>
                            <div>2. Contains at least one uppercase letter;</div>
                            <div>3. Contains at least one lowercase letter;</div>
                            <div>4. Contains at least one number;</div>
                            <div>5. Contains at least one special character.</div>
                          </div>
                        );
                      }
                      return null;
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      id="input-password-new"
                      label="New Password"
                      {...field}
                      fullWidth
                      error={error}
                      helperText={error ? error.message : null}
                      type={showPasswordFlag.new ? 'text' : 'password'}
                      InputProps={{
                        endAdornment:
  <InputAdornment position="end">
    <IconButton
      aria-label="new password visibility"
      onClick={() => handleClickShowPassword('new')}
      onMouseDown={handleMouseDownPassword}
      edge="end"
    >
      {showPasswordFlag.new ? <VisibilityOff /> : <Visibility />}
    </IconButton>
  </InputAdornment>,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="passwordAgain"
                  control={control}
                  rules={{
                    required: THIS_FIELD_IS_REQUIRED,
                    validate: (value) => {
                      if (value !== getValues('passwordNew')) {
                        return 'Password must match!';
                      }
                      return null;
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      id="input-password-again"
                      label="Confirm New Password"
                      {...field}
                      fullWidth
                      error={error}
                      helperText={error ? error.message : null}
                      type={showPasswordFlag.again ? 'text' : 'password'}
                      InputProps={{
                        endAdornment:
  <InputAdornment position="end">
    <IconButton
      aria-label="again password visibility"
      onClick={() => handleClickShowPassword('again')}
      onMouseDown={handleMouseDownPassword}
      edge="end"
    >
      {showPasswordFlag.again ? <VisibilityOff /> : <Visibility />}
    </IconButton>
  </InputAdornment>,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" fullWidth onClick={handleSubmit(changePassword)}> Submit </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
