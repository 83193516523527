import React, { useEffect, useState } from 'react';
import {
  Table, Checkbox, Accordion, AccordionDetails, Grid,
} from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  formatter,
  intFormatter,
  TOOLTIP_CONTENT,
  WELLNESS_BENEFIT_MONEY_SPENT,
  POLICY_ITEM_TYPE,
} from 'common/Constants';
import { BST_CARRIER } from 'common/AppConstant';
import { StyledTableCell, StyledTableRow } from '../common/styledTableComponent';
import PolicyTableHead from '../common/policyTableHead';
import PolicyTableCheckboxCell from '../common/policyTableCheckboxCell';
import PolicyTableInputCell from '../common/policyTableInputCell';
import PolicyAccordionSummary from '../common/policyAccordionSummary';

export default function CriticalIllness({ setCriticalFormValues }) {
  const {
    getValues, setValue, watch,
  } = useFormContext();

  const claim = useSelector((state) => state.scenario.claim);
  const brokerCode = useSelector((state) => state.auth?.brokerCode);
  const carrierCode = useSelector((state) => state.auth?.carrierCode);
  const isBSTOrBroker = !!(brokerCode || carrierCode === BST_CARRIER);

  const claimWeight = useSelector(
    (state) => state.scenario.claimWeight,
  );
  const criticalIllnessCategoryCode = useSelector(
    (state) => state.scenario.CICategoryCode,
  );

  const CIPlainList = useSelector((state) => state.scenario.CIPayoutDetail);

  const CIModuleList = useSelector((state) => state.scenario.CIModuleList);

  const countMap = ['employeeCount', 'spouseCount', 'childrenCount', 'totalCount'];

  const blankFlagObject = {};
  CIModuleList.forEach((item) => {
    blankFlagObject[item.code] = null;
  });

  const watcher = [];
  CIModuleList.forEach((moduleItem) => {
    watcher.push([]);
    criticalIllnessCategoryCode[moduleItem.code].forEach((item) => {
      watcher[watcher.length - 1].push(watch(`${item.name}Checked`));
    });
  });

  const [checkedChangingFlag, setCheckedChangingFlag] = useState(false);
  const [payout, setPayout] = useState({});
  const [count, setCount] = useState({});
  const [totalClaims, setTotalClaims] = useState({});
  const [totalSpent, setTotalSpent] = useState({});
  const [expanded, setExpanded] = useState({ ...blankFlagObject, [CIModuleList[0].code]: true });
  const [headerChecked, setHeaderChecked] = useState(blankFlagObject);
  const [headerIndeterminate, setHeaderIndeterminate] = useState(blankFlagObject);

  const setHeaderCheckboxStatus = () => {
    const output = {};
    watcher.forEach((flag, index) => {
      const n = flag.length;
      const m = flag.reduce((acc, val) => acc + val, 0);
      if (m === 0) {
        setHeaderChecked((prev) => ({ ...prev, [CIModuleList[index].code]: false }));
        setHeaderIndeterminate((prev) => ({ ...prev, [CIModuleList[index].code]: false }));
        output[CIModuleList[index].code] = true;
      } else if (m === n) {
        setHeaderChecked((prev) => ({ ...prev, [CIModuleList[index].code]: true }));
        setHeaderIndeterminate((prev) => ({ ...prev, [CIModuleList[index].code]: false }));
        output[CIModuleList[index].code] = false;
      } else {
        setHeaderChecked((prev) => ({ ...prev, [CIModuleList[index].code]: false }));
        setHeaderIndeterminate((prev) => ({ ...prev, [CIModuleList[index].code]: true }));
        output[CIModuleList[index].code] = true;
      }
    });
    return output;
  };

  const updateAllPayout = () => {
    const tempPayout = {};

    Object.values(criticalIllnessCategoryCode).forEach((list) => {
      list.forEach((item) => {
        if (item.code in claim) {
          let initialPayout = 0;
          if (item.type === POLICY_ITEM_TYPE.ORIGINAL) {
            initialPayout = getValues(item.name)
                              * Math.round(claim[item.code].employeeCount * claimWeight.CI)
                              + getValues(item.name) * Number(getValues('adultDpChecked'))
                              * Math.round(claim[item.code].spouseCount * claimWeight.CI)
                              + getValues(item.name) * Number(getValues('minorDpChecked'))
                              * Math.round(claim[item.code].childrenCount * claimWeight.CI);
            tempPayout[item.name] = initialPayout || 0;
          } else if (item.type === POLICY_ITEM_TYPE.PERCENTAGE) {
            initialPayout = Math.round((getValues('faceValue') * getValues(item.name)) / 100)
                            * (Math.round(claim[item.code].employeeCount * claimWeight.CI))
                            + Math.round(Math.round((getValues('faceValue') * getValues(item.name)) / 100)
                              * Math.round(claim[item.code].spouseCount * claimWeight.CI)
                              * ((getValues('adultDp') * Number(getValues('adultDpChecked'))) / 100))
                            + Math.round(Math.round((getValues('faceValue') * getValues(item.name)) / 100)
                              * Math.round(claim[item.code].childrenCount * claimWeight.CI)
                              * ((getValues('minorDp') * Number(getValues('minorDpChecked'))) / 100));
            tempPayout[item.name] = initialPayout || 0;
          }
        }
      });
    });

    Object.values(criticalIllnessCategoryCode).forEach((list) => {
      list.forEach((item) => {
        if (item.code in claim) {
          setPayout((prev) => ({
            ...prev,
            [item.name]: tempPayout[item.name],
          }));
          setCount((prev) => ({
            ...prev,
            [item.name]: Math.round(claim[item.code].employeeCount * claimWeight.CI)
              + Math.round(claim[item.code].spouseCount * claimWeight.CI) * Number(getValues('adultDpChecked'))
              + Math.round(claim[item.code].childrenCount * claimWeight.CI) * Number(getValues('minorDpChecked')),
          }));
        }
      });
    });

    setTotalSpent((prev) => ({
      ...prev,
      employee: CIPlainList.reduce((acc, item) => {
        if (item.code in claim) {
          if (item.type === POLICY_ITEM_TYPE.PERCENTAGE) {
            return acc + Number(getValues(`${item.name}Checked`))
            * Math.round((getValues('faceValue') * getValues(item.name)) / 100)
            * Math.round(claim[item.code].employeeCount * claimWeight.CI);
          } if (item.type === POLICY_ITEM_TYPE.ORIGINAL) {
            return acc + Number(getValues(`${item.name}Checked`)) * getValues(item.name) * Math.round(claim[item.code].employeeCount * claimWeight.CI);
          }
        }
        return acc;
      }, 0),
      spouse: CIPlainList.reduce((acc, item) => {
        if (item.code in claim) {
          if (item.type === POLICY_ITEM_TYPE.PERCENTAGE) {
            return acc + Number(getValues(`${item.name}Checked`))
            * Math.round(Math.round((getValues('faceValue') * getValues(item.name)) / 100)
            * Math.round(claim[item.code].spouseCount * claimWeight.CI)
            * (getValues('adultDp') / 100));
          } if (item.type === POLICY_ITEM_TYPE.ORIGINAL) {
            return acc + Number(getValues(`${item.name}Checked`)) * getValues(item.name) * Math.round(claim[item.code].spouseCount * claimWeight.CI);
          }
        }
        return acc;
      }, 0),
      children: CIPlainList.reduce((acc, item) => {
        if (item.code in claim) {
          if (item.type === POLICY_ITEM_TYPE.PERCENTAGE) {
            return acc + Number(getValues(`${item.name}Checked`))
            * Math.round(Math.round((getValues('faceValue') * getValues(item.name)) / 100)
            * Math.round(claim[item.code].childrenCount * claimWeight.CI)
            * (getValues('minorDp') / 100));
          } if (item.type === POLICY_ITEM_TYPE.ORIGINAL) {
            return acc + Number(getValues(`${item.name}Checked`)) * getValues(item.name) * Math.round(claim[item.code].childrenCount * claimWeight.CI);
          }
        }
        return acc;
      }, 0),
    }));

    CIModuleList.forEach((moduleItem) => {
      if (moduleItem.code !== 'general') {
        setTotalSpent((prev) => ({
          ...prev,
          [moduleItem.code]: criticalIllnessCategoryCode[moduleItem.code].reduce((acc, item) => {
            if (item.name in tempPayout) {
              return acc + tempPayout[item.name] * Number(getValues(`${item.name}Checked`));
            }
            return acc;
          }, 0),
        }));
      }
    });
  };

  useEffect(() => {
    const allItems = Object.values(criticalIllnessCategoryCode).flat();
    const itemNames = allItems.flatMap((item) => [item.name, `${item.name}Checked`]);
    const filteredValues = Object.keys(getValues()).reduce((acc, key) => {
      if (itemNames.includes(key)) {
        return { ...acc, [key]: getValues(key) };
      }
      return acc;
    }, {});
    setCriticalFormValues(filteredValues);
    const subscription = watch((values) => {
      const filteredValuesLatest = Object.keys(values).reduce((acc, key) => {
        if (itemNames.includes(key)) {
          return { ...acc, [key]: getValues(key) };
        }
        return acc;
      }, {});
      setCriticalFormValues(filteredValuesLatest);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onClickHeaderCheckbox = (key) => {
    const flag = setHeaderCheckboxStatus();
    criticalIllnessCategoryCode[key].forEach((item) => {
      setValue(`${item.name}Checked`, flag[key]);
    });
    setCheckedChangingFlag(!checkedChangingFlag);
    updateAllPayout();
  };

  const onCheckedFieldChange = () => {
    setCheckedChangingFlag(!checkedChangingFlag);
  };

  useEffect(() => {
    updateAllPayout();

    setTotalClaims((prev) => ({
      ...prev,
      general: CIPlainList.reduce((acc, item) => {
        if (item.code in claim) {
          return acc + (Math.round(claim[item.code].employeeCount * claimWeight.CI)
            + Math.round(claim[item.code].spouseCount * claimWeight.CI) * Number(getValues('adultDpChecked'))
            + Math.round(claim[item.code].childrenCount * claimWeight.CI) * Number(getValues('minorDpChecked')))
            * Number(getValues(`${item.name}Checked`));
        }
        return acc;
      }, 0),
    }));

    countMap.forEach((code) => {
      if (code !== 'totalCount') {
        setTotalClaims((prev) => ({
          ...prev,
          [code]: CIPlainList.reduce((acc, item) => {
            if (item.code in claim) {
              return acc + Math.round(claim[item.code][code] * claimWeight.CI) * Number(getValues(`${item.name}Checked`));
            }
            return acc;
          }, 0),
        }));
      }
    });

    CIModuleList.forEach((moduleItem) => {
      if (moduleItem.code !== 'general') {
        setTotalClaims((prev) => ({
          ...prev,
          [moduleItem.code]: criticalIllnessCategoryCode[moduleItem.code].reduce((acc, item) => {
            if (item.code in claim) {
              return acc + (Math.round(claim[item.code].employeeCount * claimWeight.CI)
              + Math.round(claim[item.code].spouseCount * claimWeight.CI) * Number(getValues('adultDpChecked'))
              + Math.round(claim[item.code].childrenCount * claimWeight.CI) * Number(getValues('minorDpChecked')))
              * Number(getValues(`${item.name}Checked`));
            }
            return acc;
          }, 0),
        }));
      }
    });
  }, [claim, claimWeight, checkedChangingFlag]);

  useEffect(() => {
    setHeaderCheckboxStatus();
    updateAllPayout();
  }, []);

  useEffect(() => {
    setHeaderCheckboxStatus();
  }, [checkedChangingFlag]);

  return (
    <Grid container spacing={2}>
      {CIModuleList.map((moduleItem) => (

        criticalIllnessCategoryCode[moduleItem.code].length > 0
        && moduleItem.code === 'general' && (
          <Grid item xs={12}>
            <Accordion expanded={expanded[moduleItem.code]}>
              <PolicyAccordionSummary
                id={`CI-${moduleItem.code}`}
                title={moduleItem.displayName}
                onChange={onClickHeaderCheckbox}
                setExpanded={setExpanded}
                moduleCode={moduleItem.code}
                checked={headerChecked}
                indeterminate={headerIndeterminate}
                totalClaims={totalClaims[moduleItem.code]}
                totalSpent={criticalIllnessCategoryCode[moduleItem.code].length >= 3
                  ? payout.wellness : null}
                moneySpentLabel={WELLNESS_BENEFIT_MONEY_SPENT}
                tooltipContent={TOOLTIP_CONTENT}
              />
              <AccordionDetails sx={{ padding: 0 }}>
                <TableContainer component={Paper} margin={0}>
                  <Table aria-label="main table">
                    <PolicyTableHead type={moduleItem.tableType} isCriticalIllness />
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell align="left" component="th" scope="row">
                          <Checkbox
                            id="face_value_checked"
                            disabled
                            checked
                          />
                        </StyledTableCell>
                        <StyledTableCell align="left" component="th" scope="row">
                          Face Value (FV) - Employee
                        </StyledTableCell>
                        <PolicyTableInputCell
                          name="faceValue"
                          onChange={updateAllPayout}
                          adornment="dollar"
                        />
                        <StyledTableCell align="left">
                          {intFormatter.format(totalClaims.employeeCount || 0)}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {formatter.format(totalSpent.employee || 0) ?? null}
                        </StyledTableCell>
                      </StyledTableRow>

                      <StyledTableRow>
                        <PolicyTableCheckboxCell
                          name="adultDpChecked"
                          onChange={onCheckedFieldChange}
                        />
                        <StyledTableCell align="left" component="th" scope="row">
                          Spouse (% of Employee FV)
                        </StyledTableCell>
                        <PolicyTableInputCell
                          name="adultDp"
                          onChange={updateAllPayout}
                          adornment="percent"
                        />
                        <StyledTableCell align="left">
                          {intFormatter.format(totalClaims.spouseCount || 0)}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {formatter.format(totalSpent.spouse || 0) ?? null}
                        </StyledTableCell>
                      </StyledTableRow>

                      <StyledTableRow>
                        <PolicyTableCheckboxCell
                          name="minorDpChecked"
                          onChange={onCheckedFieldChange}
                        />
                        <StyledTableCell align="left" component="th" scope="row">
                          Child(ren) (% of Employee FV)
                        </StyledTableCell>
                        <PolicyTableInputCell
                          name="minorDp"
                          onChange={updateAllPayout}
                          adornment="percent"
                        />
                        <StyledTableCell align="left">
                          {intFormatter.format(totalClaims.childrenCount || 0)}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {formatter.format(totalSpent.children || 0) ?? null}
                        </StyledTableCell>
                      </StyledTableRow>

                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </Grid>
        )

      ))}

      {CIModuleList.map((moduleItem) => (
        criticalIllnessCategoryCode[moduleItem.code].length > 0
        && moduleItem.code !== 'general' && (
        <Grid item xs={12}>
          <Accordion expanded={expanded[moduleItem.code]}>
            <PolicyAccordionSummary
              id={`CI-${moduleItem.code}`}
              title={moduleItem.displayName}
              onChange={onClickHeaderCheckbox}
              setExpanded={setExpanded}
              moduleCode={moduleItem.code}
              checked={headerChecked}
              indeterminate={headerIndeterminate}
              totalClaims={totalClaims[moduleItem.code]}
              totalSpent={totalSpent[moduleItem.code]}
            />
            <AccordionDetails sx={{ padding: 0 }}>
              <TableContainer component={Paper} margin={0}>
                <Table aria-label="main table">
                  <PolicyTableHead type={moduleItem.tableType} isCriticalIllness />
                  <TableBody>
                    {criticalIllnessCategoryCode[moduleItem.code]
                      .filter((item) => !isBSTOrBroker || item.displayName !== 'Type I Diabetes')
                      .map((item) => (
                        <StyledTableRow>
                          <PolicyTableCheckboxCell
                            name={`${item.name}Checked`}
                            onChange={onCheckedFieldChange}
                          />
                          <StyledTableCell align="left" component="th" scope="row">
                            {item.displayName}
                          </StyledTableCell>
                          <PolicyTableInputCell
                            name={item.name}
                            onChange={updateAllPayout}
                            adornment={item.type === POLICY_ITEM_TYPE.PERCENTAGE ? 'percent' : 'dollar'}
                          />
                          <StyledTableCell align="left">
                            {item.code in claim ? intFormatter.format(count[item.name]) : null}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {item.name in payout ? formatter.format(payout[item.name]) : null}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}

                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        </Grid>
        )
      ))}

    </Grid>
  );
}
