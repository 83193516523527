import store from 'app/store';
import DOMPurify from 'dompurify';
import { getDisclaimer } from 'features/user/state/service';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

export default function CarrierDisclaimer() {
  const disclaimer = useSelector((state) => state.user.disclaimer);
  const disclaimerPurified = DOMPurify.sanitize(disclaimer);

  useEffect(() => {
    store.dispatch(getDisclaimer());
  }, []);

  return (
    <div>
      {
        disclaimer
        && (
          <div dangerouslySetInnerHTML={{ __html: disclaimerPurified }} />
        )
      }
    </div>
  );
}
