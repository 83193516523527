import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

export default function CensusFileReminderDialog({ open, onClose, content }) {
  const handleDialogClose = () => {
    onClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitle color="red">Reminder</DialogTitle>
      <DialogContent>
        {content}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleDialogClose}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
