import React from 'react';
import {
  Typography, Box,
} from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import handleExport from 'common/util/canvasExportUtil';

const exportImageStyling = { cursor: 'pointer', display: 'flex', color: '#002E74' };

function ExportImage({ isOtherObservation = false, sectionRef, sectionName }) {
  return (
    <Box
      data-html2canvas-ignore
      sx={!isOtherObservation ? { ...exportImageStyling }
        : {
          ...exportImageStyling,
          marginLeft: '5px',
          marginTop: '3px',
        }}
      onClick={() => handleExport(sectionRef, sectionName)}
    >
      <Typography sx={{ fontSize: '0.875rem' }}>
        Export as Image
      </Typography>
      <FileDownloadOutlinedIcon fontSize="small" />
    </Box>
  );
}

export default ExportImage;
