import * as React from 'react';
import Box from '@mui/material/Box';
import AppBar, { DrawerHeader } from 'common/components/navigatorComponents';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Divider, Button,
} from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import ceresByMPLogo from 'asset/ceres-logo.png';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Outlet, useLocation } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import store from 'app/store';
import { useSelector } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import { deepPurple } from '@mui/material/colors';
import { Warning } from '@mui/icons-material';
import { resetJwt } from 'features/login/state/reducer';
import SideBar from './sideBar';
import { toggleSideBar, setNewScenario } from './state/reducer';

const TIMEOUT = 1200000; // 20 minutes
const PROMPT_BEFORE_IDLE = 300000; // 5 minutes

export default function Navigator() {
  const sideBarOpen = useSelector((state) => state.navigator.sideBarOpen);

  const displayName = useSelector((state) => state.auth.displayName);
  const [remaining, setRemaining] = React.useState(TIMEOUT);
  const [isWarningDialogOpen, setWarningDialog] = React.useState(false);
  const location = useLocation();

  const logout = () => {
    localStorage.clear();
    store.dispatch(resetJwt());
  };

  React.useEffect(() => {
    if (location.pathname === '/app/scenario/navigate' && !location.search) {
      store.dispatch(setNewScenario(true));
    } else if (location.pathname === '/app/scenario') {
      store.dispatch(setNewScenario(false));
    }
  }, [location]);

  const onIdle = () => {
    setWarningDialog(false);
    logout();
  };

  const onActive = () => {
    setWarningDialog(false);
  };

  const onPrompt = () => {
    setWarningDialog(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout: TIMEOUT,
    promptBeforeIdle: PROMPT_BEFORE_IDLE,
    throttle: 500,
  });

  React.useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const handleStillHere = () => {
    activate();
  };

  const toggleMenu = () => {
    store.dispatch(toggleSideBar());
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={sideBarOpen}>
        <Toolbar>
          <IconButton
            id="action-navMenu"
            onClick={() => toggleMenu()}
            sx={{
              marginRight: 5,
              ...(sideBarOpen && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box>
            <img
              src={ceresByMPLogo}
              alt="Ceres By Multiplan"
              height="auto"
              width="105"
              style={{ display: 'block', margin: '0 auto' }}
            />
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton>
            <Avatar
              sx={{ bgcolor: deepPurple[500] }}
            >
              {displayName}
            </Avatar>
          </IconButton>
        </Toolbar>
      </AppBar>
      <SideBar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Outlet />
        <Dialog open={isWarningDialogOpen} onClose={handleStillHere}>
          <DialogTitle sx={{ fontSize: '1.25rem', color: '#002E74' }}>
            <Warning sx={{ marginBottom: '-5px' }} />
            &nbsp;
            You are about to be signed out
          </DialogTitle>
          <Divider />
          <DialogContent>
            You will be signed out in&nbsp;
            {Math.ceil(remaining / 60) }
            &nbsp;mins.
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleStillHere}
              variant="contained"
            >
              REMAIN SIGNED IN
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}
