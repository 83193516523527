import React from 'react';
import { useSelector } from 'react-redux';
import store from 'app/store';
import MaterialTable from '@material-table/core';
import { useNavigate } from 'react-router-dom';
import RoutePath from 'app/router/RoutePath';
import { Delete, Edit } from '@mui/icons-material';
import ArticleIcon from '@mui/icons-material/Article';
import {
  IconButton, Link, Chip, Box,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
import { confirmDialog } from 'common/ComfirmationDialog';
import { pageSizeOptionList, defaultPageSize, defaultOrder } from 'common/Constants';
import { MAIN_HOST } from 'common/AppConstant';
import axios from 'axios';
import { deleteScenario } from './state/service';
import { resetScenario, updateScenarioSection } from './state/reducer';

export default function Scenarios() {
  const tableRef = React.useRef();
  const navigate = useNavigate();
  const shouldRefresh = useSelector((state) => state.scenario.searchRefreshFlag);
  const navigateToDetail = (id = null) => {
    store.dispatch(updateScenarioSection(0));
    let link = RoutePath.app.scenario.subpage.navigate.subpath;

    if (id && id > 0) {
      link += `?id=${id}`;
    } else {
      store.dispatch(resetScenario());
    }
    navigate(link);
  };

  React.useEffect(() => {
    store.dispatch(resetScenario());
  }, []);

  const columns = [
    {
      title: 'Name',
      field: 'name',
      width: '40%',
      render: (rowData) => (
        <IconButton
          disableFocusRipple
          disableRipple
          onClick={() => {
            navigateToDetail(rowData.id);
          }}
          style={{
            justifyContent: 'start',
            overflow: 'hidden',
            textOverflow: 'none',
            borderRadius: 0,
          }}
        >
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            component="button"
            underline="none"
            style={{
              textWrap: 'wrap',
              textAlign: 'left',
              wordBreak: 'break-all',
            }}
          >
            {rowData.name}
          </Link>
          &nbsp;
          {rowData.privateScenario
            && (
              <Chip label="Private" />
            )}
        </IconButton>
      ),
    },
    { title: 'Employer Name', field: 'employerName' },
    { title: 'Region', field: 'region' },
    { title: 'Headcount', field: 'numOfMembers' },
    {
      title: 'Census',
      field: 'censusFile',
      sorting: false,
      render: (rowData) => (
        <a href={rowData.censusFileUrl}>
          <ArticleIcon />
          {' '}
        </a>
      ),
    },
    { title: 'User', field: 'userName' },
    {
      title: 'Created',
      field: 'createdAt',
      render: (rowData) => (rowData.createdAt ? (
        <span>
          {' '}
          {new Date(rowData.createdAt).toLocaleString()}
          {' '}
        </span>
      ) : <span />),
    },
  ];
  /* eslint-disable */
  const refresh = () => {
    tableRef.current && tableRef.current.onQueryChange();
  }

  React.useEffect(() => {
    refresh();
  }, [shouldRefresh]);

  return (
    <>
      <MaterialTable
        title="Scenario list"
        tableRef={tableRef}
        columns={columns}
        components={{
          OverlayLoading: () => (<div></div>)
        }}
        localization={{
          body: {
              emptyDataSourceMessage: '',
          },
        }}
        options={{
          actionsColumnIndex: -1,
          debounceInterval: 500,
          search: true,
          pageSizeOptions: pageSizeOptionList,
          pageSize: defaultPageSize,
          emptyRowsWhenPaging: false,
        }}
        data={(query) =>
          new Promise((resolve) => {
            axios.post(`${MAIN_HOST}/scenario/search`, {
              page: query.page,
              size: query.pageSize,
              orderBy: Array.isArray(query.orderByCollection)
                        && query.orderByCollection.length === 1
                        ? columns[query.orderByCollection[0].orderBy].field
                        : 'createdAt',
              orderDirection: Array.isArray(query.orderByCollection)
                                && query.orderByCollection.length === 1
                                && query.orderByCollection[0].orderDirection
                                ? query.orderByCollection[0].orderDirection.toUpperCase()
                                : defaultOrder,
              searchTerm: query.search,
            })
            .then((res) => {
              const data = res.data;
              resolve({
                data: data.content,
                page: data.number,
                size: data.size,
                totalCount: data.totalElements,
              });
            })
            .catch(() => {
              resolve({
                data: [],
                page: 0,
                totalCount: 0,
              });
            });
          })
        }
        actions={[
          {
            icon: () => <RefreshIcon />,
            tooltip: 'Refresh Data',
            onClick: () => refresh(),
            isFreeAction: true,
          },
          {
            icon: () => <Button variant="contained" size="small">+ CREATE SCENARIO</Button>,
            tooltip: 'Add',
            isFreeAction: true,
            onClick: () => navigateToDetail(),
          },
          {
            icon: () => <Edit />,
            tooltip: 'Edit',
            onClick: (_, rowData) => navigateToDetail(rowData.id),
          },
          {
            icon: () => <Delete />,
            tooltip: 'Delete',
            onClick: (_, rowData) => {
              confirmDialog(
                () => store.dispatch(deleteScenario(rowData.id)),
                'Are you sure you wish to remove the whole scenario?',
              );
            },
          },
        ]}
      />
      <Box component="footer" sx={{ fontSize: '0.8rem', paddingTop: '25px' }}>
          MultiPlan Inc. All Rights Reserved.
      </Box>
    </>
  );
}
