import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox } from '@mui/material';
import debounce from 'lodash.debounce';
import { StyledTableCell } from './styledTableComponent';

export default function PolicyTableCheckboxCell(props) {
  const { name, onChange } = props;

  const {
    control, getValues, setValue, trigger,
  } = useFormContext();

  return (
    <StyledTableCell align="left" component="th" scope="row">
      <Controller
        name={name}
        control={control}
        defaultValue={getValues(name)}
        render={({ field, fieldState: { error } }) => (
          <Checkbox
            id={`input_${name}`}
            {...field}
            fullWidth
            error={error}
            helperText={error ? error.message : null}
            checked={field.value}
            onChange={(event) => {
              setValue(name, event.target.value === 'false' || event.target.value === false);
              setTimeout(
                debounce(() => {
                  trigger('heartAttackChecked');
                  onChange();
                }, 100),
              );
            }}
          />
        )}
      />
    </StyledTableCell>
  );
}
