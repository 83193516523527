import { createSlice } from '@reduxjs/toolkit';
import jwt from 'jwt-decode';
import { ERROR_CODE } from 'common/ErrorConstants';
import {
  COOKIE_JWT, BST_CARRIER,
} from 'common/AppConstant';
import * as API from './service';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    jwt: null,
    firstName: '',
    lastName: '',
    displayName: '',
    carrierCode: '',
    userId: '',
    carrierName: '',
    isAdmin: false,
    requiredPasswordChange: false,
    termsAccepted: null,
    errorMsg: null,
    jwtExp: null,
  },
  reducers: {
    resetJwt(state) {
      state.jwt = null;
      state.jwtExp = null;
    },
    updateJwt(state, action) {
      state.jwt = action.payload;
      localStorage.setItem(COOKIE_JWT, state.jwt);
      state.errorMsg = null;
      state.displayName = '';

      if (action.payload) {
        const userInfo = jwt(action.payload);

        if (Date.now() >= userInfo.exp * 1000) {
          state.jwt = null;
          localStorage.clear();
          return;
        }

        const {
          firstName, lastName, isAdmin, carrierCode, userId, termsAccepted, carrierName, exp,
          brokerCode, brokerName,
        } = userInfo;
        state.firstName = firstName;
        state.lastName = lastName;
        state.isAdmin = isAdmin;
        state.carrierCode = carrierCode || BST_CARRIER;
        state.userId = userId;
        state.termsAccepted = termsAccepted;
        state.carrierName = carrierName;
        state.jwtExp = exp;
        state.brokerCode = brokerCode;
        state.brokerName = brokerName;

        if (userInfo.firstName) {
          state.displayName += userInfo.firstName.charAt(0).toUpperCase();
        }

        if (userInfo.lastName) {
          state.displayName += userInfo.lastName.charAt(0).toUpperCase();
        }
      } else {
        state.firstName = '';
        state.lastName = '';
        state.displayName = '';
        state.isAdmin = false;
      }
    },
    updatePasswordChangeStatus: (state, action) => {
      state.requiredPasswordChange = action;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(API.login.fulfilled, (state, action) => {
      if (action.payload.errorCode === ERROR_CODE.INVALID_CREDENTIAL) {
        state.errorMsg = 'Incorrect Email or Password';
      } else {
        authSlice.caseReducers.updateJwt(state, action);
      }
    });
    builder.addCase(API.passwordChange.fulfilled, (state) => {
      state.requiredPasswordChange = false;
    });
    builder.addCase(API.ssoLogin.fulfilled, (state, action) => {
      authSlice.caseReducers.updateJwt(state, action);
    });
    builder.addCase(API.refreshToken.fulfilled, (state, action) => {
      authSlice.caseReducers.updateJwt(state, action);
    });
  },
});

export const {
  resetJwt,
  updateJwt,
  updatePasswordChangeStatus,
} = authSlice.actions;
export default authSlice.reducer;
