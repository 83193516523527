export default {
  login: {
    path: '/login',
  },
  aflacLogin: {
    path: '/aflac',
  },
  logout: {
    path: '/logout',
  },
  admin: {
    path: '/admin',
  },
  passwordChange: {
    path: '/app/user/passwordChange',
  },
  sso: {
    path: '/sso',
  },
  app: {
    path: '/app',
    scenario: {
      path: 'scenario',
      fullpath: '/app/scenario',
      subpage: {
        navigate: {
          subpath: 'navigate',
          path: '/app/scenario/navigate',
        },
        information: {
          subpath: 'scenario-information',
          path: '/app/scenario/scenario-information',
        },
        demographic: {
          subpath: 'demographic',
          path: '/app/scenario/demographic',
        },
      },
    },
  },

};
