import React from 'react';
import { useRoutes } from 'react-router-dom';
import Login from 'features/login/index';
import ScenarioInformation from 'features/scenario/scenarioInformation';
import Demographic from 'features/scenario/demographic';
import Navigator from 'features/navigation/index';
import Scenarios from 'features/scenario/index';
import Navigate from 'features/scenario/navigate';
import Admin from 'admin/index';
import PasswordChange from 'features/login/passwordChange';
import SSO from 'features/sso';
import AdminRoute from './AdminRoute';
import PrivateRoute from './PrivateRoute';
import RoutePath from './RoutePath';

export default function AppRoute() {
  return useRoutes([
    {
      path: '*',
      element: <Login />,
    },
    {
      path: RoutePath.login.path,
      element: <Login />,
    },
    {
      path: RoutePath.aflacLogin.path,
      element: <Login />,
    },
    {
      path: RoutePath.sso.path,
      element: <SSO />,
    },
    {
      path: RoutePath.admin.path,
      element:
  <AdminRoute>
    <Admin />
  </AdminRoute>,
    },
    {
      path: RoutePath.passwordChange.path,
      element: <PasswordChange />,
    },
    {
      path: RoutePath.app.path,
      element:
  <PrivateRoute>
    <Navigator />
  </PrivateRoute>,
      children: [
        {
          path: RoutePath.app.scenario.path,
          element: <Scenarios />,
        },
        {
          path: `${RoutePath.app.scenario.subpage.navigate.path}`,
          element: <Navigate />,
        },
        {
          path: `${RoutePath.app.scenario.subpage.information.path}`,
          element: <ScenarioInformation />,
        },
        {
          path: RoutePath.app.scenario.subpage.demographic.path,
          element: <Demographic />,
        },

      ],
    },
  ]);
}
