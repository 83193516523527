import React, {
  useState, useEffect, useMemo, useRef,
} from 'react';
import {
  Grid, Typography, Card, Divider, Slider, Tooltip, Switch,
} from '@mui/material';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import KingBedIcon from '@mui/icons-material/KingBed';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import WheelchairPickupIcon from '@mui/icons-material/WheelchairPickup';
import { useSelector } from 'react-redux';
import store from 'app/store';
import BottomBar from 'features/navigation/bottomBar';
import {
  ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid,
  Label,
} from 'recharts';
import { grey } from '@mui/material/colors';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { fixedProductPriority, specificBenefitChecked } from 'common/Constants';
import ExportImage from 'common/components/exportImage';
import PolicyCardWithBar from './common/policyCardWithBar';
import HospitalIndemnityDialog from './policyModelTable/hospitalIndemnityDialog';
import CriticalIllnessDialog from './policyModelTable/criticalIllnessDialog';
import AccidentDialog from './policyModelTable/accidentDialog';
import {
  saveHospitalIndemnity, saveCriticalIllness, saveAccident, savePolicyAdjustments,
  saveParticipationPerc, sendErrorEmail, cancelErrorEmail,
} from './state/service';

const gridItemStyle = {
  display: 'flex',
  alignItems: 'center',
  height: '450px',
};

export default function Policy(props) {
  const { bottomBarFunctions } = props;
  const { handleCancelCreation, goToNextPage, goToPreviousPage } = bottomBarFunctions;
  const mostCommonPayoutRef = useRef();

  const scenarioId = useSelector((state) => state.scenario.scenarioId);
  const totalPayout = useSelector((state) => state.scenario.totalPayout);
  const totalCount = useSelector((state) => state.scenario.totalCount);
  const HIPayoutDetail = useSelector((state) => state.scenario.HIPayoutDetail);
  const CIPayoutDetail = useSelector((state) => state.scenario.CIPayoutDetail);
  const ACPayoutDetail = useSelector((state) => state.scenario.ACPayoutDetail);
  const hospitalIndemnityObject = useSelector((state) => state.scenario.hospitalIndemnityObject);
  const criticalIllnessObject = useSelector((state) => state.scenario.criticalIllnessObject);
  const accidentObject = useSelector((state) => state.scenario.accidentObject);
  const carrierCode = useSelector((state) => state.auth.carrierCode);
  const hospitalIndemnityFlag = useSelector((state) => state.scenario.hospitalIndemnityFlag);
  const criticalIllnessFlag = useSelector((state) => state.scenario.criticalIllnessFlag);
  const accidentFlag = useSelector((state) => state.scenario.accidentFlag);
  const policyAdjustments = useSelector((state) => state.scenario.policyAdjustments);
  const currPage = useSelector((state) => state.scenario.scenarioSection);

  const hasCI = useMemo(() => !!totalPayout?.CI?.payout, [totalPayout?.CI]);

  const methods = useForm({ defaultValues: policyAdjustments, mode: 'onBlur' });
  const {
    reset, handleSubmit, control, setValue, getValues,
  } = methods;

  const [HIDialogOpen, setHIDialogOpen] = useState(false);
  const [CIDialogOpen, setCIDialogOpen] = useState(false);
  const [ACDialogOpen, setACDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('Details');

  const wellnessHIBenefit = HIPayoutDetail?.find((benefit) => benefit?.code === 'wellness2');
  const wellnessCIBenefit = CIPayoutDetail?.find((benefit) => benefit?.code === 'wellness2');
  const wellnessACBenefit = ACPayoutDetail?.find((benefit) => benefit?.code === 'wellness2');

  const wellnessHIChecked = hospitalIndemnityObject[specificBenefitChecked[carrierCode].HI];
  const wellnessCIChecked = criticalIllnessObject[specificBenefitChecked[carrierCode].CI];
  const wellnessACChecked = accidentObject[specificBenefitChecked[carrierCode].AC];

  const atLeastoneHIChecked = HIPayoutDetail
    .filter((benefit) => hospitalIndemnityObject[`${benefit?.name}Checked`])
    .filter((benefit) => benefit?.code !== 'wellness2').length > 0;
  const atLeastoneCIChecked = CIPayoutDetail
    .filter((benefit) => criticalIllnessObject[`${benefit?.name}Checked`])
    .filter((benefit) => benefit?.code !== 'wellness2').length > 0;
  const atLeastoneACChecked = ACPayoutDetail
    .filter((benefit) => accidentObject[`${benefit?.name}Checked`])
    .filter((benefit) => benefit?.code !== 'wellness2').length > 0;

  const [isFirstRender, setIsFirstRender] = useState(true);

  const [productStateInvalid, setProductStateInvalid] = useState(null);

  const handleHIDialogOpen = (title = 'details') => {
    setHIDialogOpen(true);
    setDialogTitle(title);
  };

  const handleHIDialogClose = () => {
    setHIDialogOpen(false);
  };

  const handleCIDialogOpen = (title = 'details') => {
    setCIDialogOpen(true);
    setDialogTitle(title);
  };

  const handleCIDialogClose = () => {
    setCIDialogOpen(false);
  };

  const handleACDialogOpen = (title = 'details') => {
    setACDialogOpen(true);
    setDialogTitle(title);
  };

  const handleACDialogClose = () => {
    setACDialogOpen(false);
  };

  const saveHospitalIndemnityObject = (saveData) => {
    const benefit1 = {};
    const benefit2 = {};
    Object.keys(saveData)?.forEach((benefitKey) => {
      if (benefitKey.charAt(benefitKey.length - 1) === '2') {
        const benefit2Key = benefitKey.slice(0, -1);
        benefit2[benefit2Key] = saveData[benefitKey];
      } else {
        benefit1[benefitKey] = saveData[benefitKey];
      }
    });
    benefit2.defaultValueChecked = benefit1?.defaultValueChecked;
    benefit2.carrierId = benefit1?.carrierId;
    benefit1.scenarioId = scenarioId;
    benefit2.scenarioId = scenarioId;
    store.dispatch(saveHospitalIndemnity({ benefit1, benefit2 }));
    store.dispatch(saveParticipationPerc({
      rate1: Number(saveData?.participationPerc1),
      rate2: Number(saveData?.participationPerc2),
      scenarioId,
      product: 'HI',
    }));
    setHIDialogOpen(false);
  };

  const saveCriticalIllnessObject = (saveData) => {
    const benefit1 = {};
    const benefit2 = {};
    Object.keys(saveData)?.forEach((benefitKey) => {
      if (benefitKey.charAt(benefitKey.length - 1) === '2') {
        const benefit2Key = benefitKey.slice(0, -1);
        benefit2[benefit2Key] = saveData[benefitKey];
      } else {
        benefit1[benefitKey] = saveData[benefitKey];
      }
    });
    benefit2.defaultValueChecked = benefit1?.defaultValueChecked;
    benefit2.carrierId = benefit1?.carrierId;
    benefit1.scenarioId = scenarioId;
    benefit2.scenarioId = scenarioId;
    store.dispatch(saveCriticalIllness({ benefit1, benefit2 }));
    setCIDialogOpen(false);
  };

  const saveAccidentObject = (saveData) => {
    const benefit1 = {};
    const benefit2 = {};
    Object.keys(saveData)?.forEach((benefitKey) => {
      if (benefitKey.charAt(benefitKey.length - 1) === '2') {
        const benefit2Key = benefitKey.slice(0, -1);
        benefit2[benefit2Key] = saveData[benefitKey];
      } else {
        benefit1[benefitKey] = saveData[benefitKey];
      }
    });
    benefit2.defaultValueChecked = benefit1?.defaultValueChecked;
    benefit2.carrierId = benefit1?.carrierId;
    benefit1.scenarioId = scenarioId;
    benefit2.scenarioId = scenarioId;
    store.dispatch(saveAccident({ benefit1, benefit2 }));
    store.dispatch(saveParticipationPerc({
      rate1: Number(saveData?.participationPerc1),
      rate2: Number(saveData?.participationPerc2),
      scenarioId,
      product: 'AC',
    }));
    setACDialogOpen(false);
  };

  const handleSaveAdjustments = (saveData) => {
    store.dispatch(savePolicyAdjustments({ ...saveData, scenarioId }));
  };

  const handleSendErrorEmail = () => {
    store.dispatch(sendErrorEmail(scenarioId));
  };

  const handleCancelErrorEmail = () => {
    store.dispatch(cancelErrorEmail(scenarioId));
  };

  const invalidProducts = () => {
    const HIWellnessPayout = wellnessHIChecked ? wellnessHIBenefit?.payout?.TOTAL : 0;
    const HIWellnessCount = wellnessHIChecked ? wellnessHIBenefit?.count?.TOTAL : 0;
    const HIWellInvalid = (HIWellnessPayout === 0 || HIWellnessCount === 0) && wellnessHIChecked;

    const CIWellnessPayout = wellnessCIChecked ? wellnessCIBenefit?.payout?.TOTAL : 0;
    const CIWellnessCount = wellnessCIChecked ? wellnessCIBenefit?.count?.TOTAL : 0;
    const CIWellInvalid = (CIWellnessPayout === 0 || CIWellnessCount === 0) && wellnessCIChecked;

    const ACWellnessPayout = wellnessACChecked ? wellnessACBenefit?.payout?.TOTAL : 0;
    const ACWellnessCount = wellnessACChecked ? wellnessACBenefit?.count?.TOTAL : 0;
    const ACWellInvalid = (ACWellnessPayout === 0 || ACWellnessCount === 0) && wellnessACChecked;

    const wellnessInvalid = HIWellInvalid || CIWellInvalid || ACWellInvalid;

    const HIPayout = totalPayout.HI.payout.TOTAL - HIWellnessPayout;
    const HICount = totalCount.HI.count.TOTAL - HIWellnessCount;
    const HIInvalid = (HIPayout === 0 || HICount === 0) && atLeastoneHIChecked;

    const CIPayout = totalPayout.CI.payout.TOTAL - CIWellnessPayout;
    const CICount = totalCount.CI.count.TOTAL - CIWellnessCount;
    const CIInvalid = (CIPayout === 0 || CICount === 0) && atLeastoneCIChecked;

    const ACPayout = totalPayout.AC.payout.TOTAL - ACWellnessPayout;
    const ACCount = totalCount.AC.count.TOTAL - ACWellnessCount;
    const ACInvalid = (ACPayout === 0 || ACCount === 0) && atLeastoneACChecked;

    const productsInvalid = HIInvalid || CIInvalid || ACInvalid;

    const overallProductsInvalid = wellnessInvalid || productsInvalid;
    return overallProductsInvalid;
  };

  useEffect(() => {
    reset(policyAdjustments);
  }, [policyAdjustments, currPage]);

  useEffect(() => {
    if (isFirstRender) {
      const state = invalidProducts();
      setProductStateInvalid(state);
      setIsFirstRender(false);
    } else {
      const currentStateInvalid = invalidProducts();
      if (!productStateInvalid && currentStateInvalid) {
        handleSendErrorEmail();
      } else if (productStateInvalid && !currentStateInvalid) {
        handleCancelErrorEmail();
      }
      setProductStateInvalid(currentStateInvalid);
    }
  }, [totalPayout, totalCount]);

  const filterAllBenefits = () => {
    const HIFilter = HIPayoutDetail.filter((benefit) => hospitalIndemnityObject[`${benefit?.name}Checked`]);
    const CIFilter = CIPayoutDetail.filter((benefit) => criticalIllnessObject[`${benefit?.name}Checked`] && benefit?.module?.code !== 'general');
    const ACFilter = ACPayoutDetail.filter((benefit) => accidentObject[`${benefit?.name}Checked`]);
    const allBenefits = [...HIFilter, ...CIFilter, ...ACFilter];
    return allBenefits.filter((benefit) => benefit?.count?.TOTAL > 0 && benefit?.code !== 'wellness2');
  };

  const getTop10CommonPayoutBenefits = () => {
    const filteredBenefits = filterAllBenefits();
    if (filteredBenefits?.length === 0) {
      return 'No benefits to display';
    }
    const sortByClaims = filteredBenefits.sort((a, b) => {
      const first = a?.count?.TOTAL;
      const second = b?.count?.TOTAL;
      if (first !== second) {
        return second - first;
      }
      const handleTies = fixedProductPriority[a?.policyCode] - fixedProductPriority[b?.policyCode];
      return handleTies;
    });
    const addCustomYAxisLabels = sortByClaims.slice(0, 10).map((benefit) => ({
      ...benefit,
      pCodeDisplayName: `${benefit?.policyCode} - ${benefit?.displayName}`,
    }));
    return addCustomYAxisLabels;
  };

  const top10CommonPayoutBenefits = getTop10CommonPayoutBenefits();

  const valuetext = (value) => `${value}`;
  const marks = [
    { value: 0, label: '0' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 75, label: '75' },
    { value: 100, label: '100' },
  ];

  return (
    <FormProvider {...methods}>
      <Grid container spacing={2} padding={3}>
        <HospitalIndemnityDialog
          open={HIDialogOpen}
          save={saveHospitalIndemnityObject}
          handleClose={handleHIDialogClose}
          title={dialogTitle}
        />
        <CriticalIllnessDialog
          open={CIDialogOpen}
          save={saveCriticalIllnessObject}
          handleClose={handleCIDialogClose}
          title={dialogTitle}
        />
        <AccidentDialog
          open={ACDialogOpen}
          save={saveAccidentObject}
          handleClose={handleACDialogClose}
          title={dialogTitle}
        />
        <Grid item xs={12}>
          <Typography variant="h4">
            Policies Selection &amp; Comparison
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            Here you can review and edit product offerings, underlying assumptions,
            and even which benefits and sub-benefits are covered.
            Ultimately, the goal is to compare and contrast the impact
            the insurance has on members and their finances.
          </Typography>
        </Grid>
        <Grid item container xs={12} spacing={4}>
          <Grid item xs={6} paddingRight={2}>
            <Grid container item xs={12}>
              <Grid item xs={3}>
                <Typography variant="body1" color={grey[800]}>
                  Participation Rate
                </Typography>
              </Grid>
              &nbsp;
              <Grid item xs={1}>
                <Tooltip
                  style={{ paddingTop: '3px' }}
                  title="This represents the expected participation by product. Active enrollment conditions will increase the expected participation and a passive enrollment will decrease participation."
                >
                  <InfoOutlinedIcon fontSize="small" />
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" color={grey[800]} style={{ float: 'right' }}>
                  Different by Product
                </Typography>
              </Grid>
              <Grid item xs={1} sx={{ marginTop: '-2px' }}>
                <Controller
                  name="diff"
                  control={control}
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <Switch
                      {...field}
                      error={error}
                      aria-label="diff"
                      size="small"
                      checked={getValues('diff')}
                      onChange={(_, value) => {
                        setValue('diff', value);
                        handleSubmit(handleSaveAdjustments)();
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Controller
              name="enrollCond"
              control={control}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <Slider
                  {...field}
                  error={error}
                  aria-label="enrollCond"
                  getAriaValueText={valuetext}
                  step={1}
                  valueLabelDisplay="auto"
                  marks={marks}
                  disabled={getValues('diff')}
                  onChangeCommitted={(_, value) => {
                    setValue('enrollCond', value);
                    handleSubmit(handleSaveAdjustments)();
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} paddingRight={2}>
            <Grid container item xs={12}>
              <Grid item>
                <Typography variant="body1" color={grey[800]} display="inline">
                  Percentage of Claims Filed
                </Typography>
              </Grid>
              <Grid item>
                <Tooltip
                  style={{ paddingTop: '3px', marginLeft: '5px' }}
                  title="This represents the percentage of total claims we expect to be paid. Auto-paid claims will result in a significantly higher percentage of paid claims."
                >
                  <InfoOutlinedIcon fontSize="small" />
                </Tooltip>
              </Grid>
            </Grid>
            <Controller
              name="comLogic"
              control={control}
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <Slider
                  {...field}
                  error={error}
                  aria-label="comLogic"
                  getAriaValueText={valuetext}
                  step={1}
                  valueLabelDisplay="auto"
                  marks={marks}
                  onChangeCommitted={(_, value) => {
                    setValue('comLogic', value);
                    handleSubmit(handleSaveAdjustments)();
                  }}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} item xs={12}>
          <Grid item xs={4}>
            <PolicyCardWithBar
              handleDialogOpen={handleHIDialogOpen}
              hasCheck
              checked={hospitalIndemnityFlag}
              policies={1}
              benefits={7}
              payout={totalPayout.HI.payout.TOTAL}
              people={totalCount.HI.count.TOTAL}
              displayName="Hospital Indemnity"
              backgroundIcon={<KingBedIcon sx={{ fontSize: '100px' }} />}
              controllerName="enrollCondHi"
              handleSave={handleSaveAdjustments}
              valuetext={valuetext}
              marks={marks}
              sliderDisabled={!getValues('diff')}
              specificBenefit={wellnessHIBenefit}
              specificBenefitChecked={wellnessHIChecked}
            />
          </Grid>
          {hasCI && (
            <Grid item xs={4}>
              <PolicyCardWithBar
                handleDialogOpen={handleCIDialogOpen}
                hasCheck
                checked={criticalIllnessFlag}
                policies={5}
                benefits={31}
                payout={totalPayout.CI.payout.TOTAL}
                people={totalCount.CI.count.TOTAL}
                displayName="Critical Illness"
                backgroundIcon={<LocalHospitalIcon sx={{ fontSize: '100px' }} />}
                controllerName="enrollCondCi"
                handleSave={handleSaveAdjustments}
                valuetext={valuetext}
                marks={marks}
                sliderDisabled={!getValues('diff')}
                specificBenefit={wellnessCIBenefit}
                specificBenefitChecked={wellnessCIChecked}
              />
            </Grid>
          )}
          <Grid item xs={4}>
            <PolicyCardWithBar
              handleDialogOpen={handleACDialogOpen}
              hasCheck
              checked={accidentFlag}
              policies={4}
              benefits={40}
              payout={totalPayout.AC.payout.TOTAL}
              people={totalCount.AC.count.TOTAL}
              displayName="Accident"
              backgroundIcon={<WheelchairPickupIcon sx={{ fontSize: '100px' }} />}
              controllerName="enrollCondAc"
              handleSave={handleSaveAdjustments}
              valuetext={valuetext}
              marks={marks}
              sliderDisabled={!getValues('diff')}
              specificBenefit={wellnessACBenefit}
              specificBenefitChecked={wellnessACChecked}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card ref={mostCommonPayoutRef}>
            <Grid container spacing={2}>
              <Grid item xs={12} container variant="addPaddingBottom" justifyContent="space-between">
                <Typography variant="h5">
                  Most Frequent Claim Types
                </Typography>
                <ExportImage sectionRef={mostCommonPayoutRef} sectionName="most_frequent_claim_types" />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sx={gridItemStyle}>
                {typeof top10CommonPayoutBenefits === 'string' ? (
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography>No benefits to display</Typography>
                  </Grid>
                ) : (
                  <ResponsiveContainer width={850} height={375}>
                    <BarChart
                      data={top10CommonPayoutBenefits}
                      layout="vertical"
                    >
                      <XAxis type="number" orientation="bottom" stroke="#212121" tickLine={false} axisLine={false} height={55}>
                        <Label value="Claims per Category" position="insideBottom" style={{ fill: '#212121' }} />
                      </XAxis>
                      <YAxis
                        type="category"
                        dataKey="pCodeDisplayName"
                        width={390}
                        axisLine
                        tickLine={false}
                        dx={-10}
                        style={{ fill: '#212121' }}
                      />
                      <CartesianGrid horizontal={false} vertical color="#212121" />
                      <Bar dataKey={(benefit) => benefit?.count?.TOTAL} fill="#002E74" />
                    </BarChart>
                  </ResponsiveContainer>
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <BottomBar
          firstButtonDisplayName="EXIT"
          secondButtonDisplayName="GO TO COVERAGE"
          onClickFirstButton={handleCancelCreation}
          onClickSecondButton={goToNextPage}
          isBackButton
          onClickBackButton={goToPreviousPage}
        />
      </Grid>
    </FormProvider>
  );
}
