import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TextField, InputAdornment } from '@mui/material';
import debounce from 'lodash.debounce';
import { THIS_FIELD_IS_REQUIRED } from 'common/ErrorConstants';
import { StyledTableCell } from './styledTableComponent';

export default function PolicyTableInputCell(props) {
  const {
    name, onChange, adornment, min, max, isBenefit2, isDisabled,
  } = props;

  const inputAdornment = {};
  if (adornment === 'percent') {
    inputAdornment.endAdornment = <InputAdornment position="end">%</InputAdornment>;
  } else if (adornment === 'dollar') {
    inputAdornment.startAdornment = <InputAdornment position="start">$</InputAdornment>;
  }

  const {
    control, getValues, setValue, trigger,
  } = useFormContext();

  return (
    <StyledTableCell align="left">
      <Controller
        name={name}
        control={control}
        defaultValue={getValues(name)}
        rules={{
          required: isBenefit2 ? false : THIS_FIELD_IS_REQUIRED,
          validate: (value) => {
            const temp = +value;
            if (Number.isInteger(temp) === false) {
              return 'Please enter an integer number';
            }
            return null;
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            id={`input_${name}`}
            label=""
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            variant="standard"
            {...field}
            maxWidth="xs"
            InputProps={inputAdornment}
            error={error}
            helperText={error ? error.message : null}
            onWheel={(e) => e.target.blur()}
            onChange={(event) => {
              let temp = event.target.value;
              if (!temp && temp !== 0 && temp !== '0') {
                if (name !== 'participationPerc1') {
                  temp = null;
                }
              } else if (event.target.value < 0 || (min && event.target.value < min)) {
                temp = 0;
              } else if (max && event.target.value > max) {
                temp = max;
              } else if (adornment === 'percent') {
                temp = Math.min(100, temp);
              }
              setValue(name, temp);
              if (name === 'participationPerc1') {
                setValue('participationPerc2', 100 - temp);
              }
              setTimeout(
                debounce(() => {
                  trigger(name);
                  onChange();
                }, 100),
              );
            }}
            disabled={isDisabled}
          />
        )}
      />
    </StyledTableCell>
  );
}
