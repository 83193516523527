import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { MAIN_HOST } from 'common/AppConstant';

export const TOKEN_REFRESH_URL = `${MAIN_HOST}/token/refresh`;

export const login = createAsyncThunk(
  'auth/login',
  async (data) => {
    const response = await axios.post(`${MAIN_HOST}/auth/login`, data);
    return response.data;
  },
);

export const passwordChange = createAsyncThunk(
  'auth/password-change',
  async (data) => {
    const response = await axios.post(`${MAIN_HOST}/auth/password-change`, data);
    return response.data;
  },
);

export const ssoLogin = createAsyncThunk(
  'auth/sso/login',
  async (data) => {
    const response = await axios.get(`${MAIN_HOST}/auth/jwtprovider?oneTimeKey=${data}`);
    return response.data;
  },
);

export const refreshToken = createAsyncThunk(
  'token/refresh',
  async () => {
    const response = await axios.get(TOKEN_REFRESH_URL);
    return response.data;
  },
);
export default login;
