import { createSlice } from '@reduxjs/toolkit';

export const navigatorSlice = createSlice({
  name: 'Navigator',
  initialState: {
    sideBarOpen: false,
    isNewScenario: false,
  },
  reducers: {
    toggleSideBar(state) {
      state.sideBarOpen = !state.sideBarOpen;
    },
    setNewScenario(state, action) {
      state.isNewScenario = action.payload;
    },
  },
});

export const { toggleSideBar, setNewScenario } = navigatorSlice.actions;
export default navigatorSlice.reducer;
