import React from 'react';
import {
  Card, Grid, Typography, Box,
} from '@mui/material';
import * as Constants from 'common/Constants';

export default function PolicyCardSupplemental(props) {
  const {
    policyCode, backgroundIcon, avgHospitalBenefit = 0, highestCIBenefit = {},
    top5AccidentBenefits,
  } = props;

  return (
    <Grid
      item
      container
      xs={12}
    >
      <Card
        sx={{
          borderRadius: '12px 12px 0 0',
          padding: '0.5rem',
          backgroundColor: '#002E74',
          height: '125px',
          width: '100%',
          margin: 0,
        }}
      >
        <Typography
          sx={{
            fontSize: '0.875rem',
            fontWeight: '600',
            color: Constants.WHITE,
          }}
        >
          {Constants.policyDisplayNames[policyCode].toUpperCase()}
        </Typography>
        <Box sx={{ textAlign: 'center', padding: '10px' }}>
          <img
            src={backgroundIcon}
            alt={Constants.policyDisplayNames[policyCode]}
            width={policyCode === 'HI' ? '70' : '80'}
          />
        </Box>
      </Card>
      <Card
        sx={{
          borderRadius: '0 0 12px 12px',
          margin: 0,
          width: '100%',
          backgroundColor: Constants.WHITE,
          padding: '1rem',
          height: '165px',
        }}
      >
        {policyCode === 'AC' ? (
          <Box>
            <Typography sx={{ fontSize: '1.5rem', fontWeight: '600', textAlign: 'center' }}>
              Top 5 Accident Benefits
            </Typography>
            <Box>
              {typeof top5AccidentBenefits === 'string' ? (
                <Typography sx={{ marginTop: '2rem', marginLeft: '4.5rem' }}>No benefits to display</Typography>
              )
                : top5AccidentBenefits?.map((el, i) => (
                  <Typography sx={{ fontSize: '12px' }}>
                    {`${i + 1}. ${el}`}
                  </Typography>
                ))}
            </Box>
          </Box>
        ) : (
          <Box sx={{ marginTop: '25px', textAlign: 'center' }}>
            {(policyCode === 'CI' && typeof highestCIBenefit === 'string') ? (
              <Typography sx={{ marginTop: '3rem' }}>No benefits to display</Typography>
            ) : (
              <>
                <Typography sx={{ fontSize: '2rem', fontWeight: 600 }}>
                  {Constants.formatter.format(policyCode === 'HI' ? avgHospitalBenefit : highestCIBenefit?.payout?.TOTAL)}
                </Typography>
                <Typography sx={{ fontSize: '0.875rem' }}>
                  {policyCode === 'HI' ? 'Projected Average Hospital Benefit' : `Highest Benefit Amount: ${highestCIBenefit?.displayName}`}
                </Typography>
              </>
            )}
          </Box>
        )}
      </Card>
    </Grid>
  );
}
