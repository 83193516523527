import React from 'react';
import {
  Card, Grid, Typography, Slider,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import * as Constants from 'common/Constants';
import { formatter, intFormatter } from 'common/Constants';
import getSeperateBenefitName from 'common/util/benefitNameUtil';

export default function PolicyCardWithBar(props) {
  const {
    handleDialogOpen, checked,
    // policies, benefits,
    payout, people,
    displayName, controllerName, handleSave, valuetext, marks,
    sliderDisabled, specificBenefit, specificBenefitChecked,
  } = props;
  const specificPayout = specificBenefitChecked ? specificBenefit?.payout?.TOTAL : 0;
  const specificPeople = specificBenefitChecked ? specificBenefit?.count?.TOTAL : 0;
  const overallPayout = specificBenefitChecked ? (payout - specificPayout) : payout;
  const overallPeople = specificBenefitChecked ? (people - specificPeople) : people;

  const {
    control, setValue, handleSubmit,
  } = useFormContext();

  return (
    <Grid
      item
      container
      xs={12}
      style={{
        width: '100%',
        position: 'relative',
      }}
      onMouseDown={(event) => {
        if (!event.target.className.startsWith('MuiSlider')) {
          handleDialogOpen(displayName);
        }
      }}
      sx={{ cursor: 'pointer' }}
    >
      <Card
        sx={{
          borderRadius: '12px 12px 0 0',
          margin: 0,
          zIndex: 1100,
          position: 'relative',
          paddingTop: '32px',
          paddingBottom: '16px',
          width: '100%',
          top: '0%',
          backgroundColor: checked ? Constants.PRIMARY_DARK : 'white',
        }}
      >
        <Typography
          variant="h4"
          align="center"
          color={checked ? 'white' : Constants.PRIMARY_DARK}
          sx={{ zIndex: 1450, marginBottom: '24px' }}
        >
          {displayName}
        </Typography>

        <Controller
          name={controllerName}
          control={control}
          defaultValue=""
          render={({ field, fieldState: { error } }) => (
            !sliderDisabled
            && (
              <Slider
                {...field}
                error={error}
                id="slider"
                aria-label={controllerName}
                getAriaValueText={valuetext}
                step={1}
                valueLabelDisplay="auto"
                marks={marks}
                sx={{
                  '& .MuiSlider-markLabel': {
                    color: checked ? 'white' : 'black',
                  },
                  zIndex: 1500,
                }}
                onChangeCommitted={(_, value) => {
                  setValue(controllerName, value);
                  handleSubmit(handleSave)();
                }}
              />
            )
          )}
        />

      </Card>
      <Card
        sx={{
          borderRadius: '0 0 12px 12px',
          zIndex: 1000,
          margin: 0,
          position: 'relative',
          width: '100%',
          backgroundColor: Constants.WHITE,
          paddingBottom: '1.5rem',
        }}
      >
        <Grid
          container
          item
          xs={12}
          spacing={3}
        >
          <Grid item container xs={6}>
            <Grid item container direction="column" spacing={3}>
              <Grid item>
                <Typography variant="body1" fontSize="1.25rem" fontWeight="bold" textAlign="center">
                  {formatter.format(overallPayout < 0 ? 0 : overallPayout)}
                </Typography>
                <Typography variant="body1" fontSize="0.875rem" textAlign="center">
                  Projected Claims
                </Typography>
              </Grid>
              <Grid item>
                <Typography marginTop="1.3rem" variant="body1" fontSize="1.25rem" fontWeight="bold" textAlign="center">
                  {formatter.format(specificPayout)}
                </Typography>
                <Typography variant="body1" fontSize="0.875rem" textAlign="center">
                  {getSeperateBenefitName(specificBenefit)}
                </Typography>
                <Typography variant="body1" fontSize="0.875rem" textAlign="center">
                  Projected Claims
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={6}>
            <Grid item container direction="column" spacing={3}>
              <Grid item>
                <Typography variant="body1" fontSize="1.25rem" fontWeight="bold" textAlign="center">
                  {intFormatter.format(overallPeople < 0 ? 0 : overallPeople)}
                </Typography>
                <Typography variant="body1" fontSize="0.875rem" textAlign="center">
                  Projected Number of Claims
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" fontSize="1.25rem" fontWeight="bold" textAlign="center">
                  {intFormatter.format(specificPeople)}
                </Typography>
                <Typography variant="body1" fontSize="0.875rem" textAlign="center">
                  {getSeperateBenefitName(specificBenefit)}
                </Typography>
                <Typography variant="body1" fontSize="0.875rem" textAlign="center">
                  Projected Number of Claims
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}
