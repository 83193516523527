import React from 'react';
import { Navigate } from 'react-router-dom';
import { COOKIE_JWT } from 'common/AppConstant';
import store from 'app/store';
import { updateJwt } from 'features/login/state/reducer';
import { AFLAC } from 'common/Constants';
import { useSelector } from 'react-redux';
import RoutePath from './RoutePath';

export default function PrivateRoute({ children }) {
  const carrierCode = useSelector((state) => state.auth.carrierCode);
  const foundJwt = localStorage.getItem(COOKIE_JWT);
  if (!foundJwt || foundJwt === 'null') {
    if (carrierCode === AFLAC.CODE) {
      return <Navigate to={RoutePath.aflacLogin.path} replace />;
    }
    return <Navigate to={RoutePath.login.path} replace />;
  }
  store.dispatch(updateJwt(foundJwt));
  return children;
}
