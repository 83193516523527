import { CircularProgress } from '@mui/material';
import React from 'react';
import './spinner.css';

function Spinner() {
  return (
    <div className="loading">
      <div className="spinner-container">
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
          }}
          size={40}
          thickness={4}
        />
      </div>
    </div>
  );
}
export default Spinner;
