export const THIS_FIELD_IS_REQUIRED = 'This field is required.';

export const FILE_MISSING = 'File is required. Please upload one.';

export default THIS_FIELD_IS_REQUIRED;

export const DUPLICATED_RECORD = 'DUPLICATED_RECORD';

export const ERROR_CODE = {
  DUPLICATED_RECORD: 'DUPLICATED_RECORD',
  VALIDATION_ERROR: 'VALIDATION_ERROR',
  INVALID_CREDENTIAL: 'INVALID_CREDENTIAL',
};
