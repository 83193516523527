import React from 'react';
import {
  Card, Grid, Typography,
} from '@mui/material';
import * as Constants from 'common/Constants';
import { formatter, intFormatter } from 'common/Constants';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import getSeperateBenefitName from 'common/util/benefitNameUtil';

export default function PolicyCard(props) {
  const {
    handleDialogOpen, hasCheck, checked,
    // policies, benefits,
    payout, people,
    displayName, specificBenefit, specificBenefitChecked,
  } = props;

  const specificPayout = specificBenefitChecked ? specificBenefit?.payout?.TOTAL : 0;
  const specificPeople = specificBenefitChecked ? specificBenefit?.count?.TOTAL : 0;
  const overallPayout = specificBenefitChecked ? (payout - specificPayout) : payout;
  const overallPeople = specificBenefitChecked ? (people - specificPeople) : people;

  return (
    <Grid
      item
      container
      xs={12}
      style={{
        width: '100%',
        position: 'relative',
      }}
      onClick={() => {
        if (handleDialogOpen !== undefined) {
          handleDialogOpen(displayName);
        }
      }}
      sx={{ cursor: 'pointer' }}
    >
      <Card
        sx={{
          borderRadius: '12px 12px 0 0',
          margin: 0,
          zIndex: 1100,
          position: 'relative',
          paddingTop: '32px',
          paddingBottom: '16px',
          width: '100%',
          top: '0%',
          backgroundColor: Constants.PRIMARY_DARK,
        }}
      >
        <Typography sx={{
          right: '2%', top: '5%', zIndex: 1500, position: 'absolute',
        }}
        >
          {hasCheck && (checked
            ? (
              <CheckCircleIcon sx={{
                color: Constants.CHECK_GREEN,
                backgroundColor: 'white',
                borderRadius: '50%',
              }}
              />
            )
            : <RadioButtonUncheckedIcon />)}
        </Typography>
        <Typography
          variant="h4"
          align="center"
          color={Constants.WHITE}
          sx={{ zIndex: 1450, marginBottom: '24px' }}
        >
          {displayName}
        </Typography>
      </Card>
      <Card
        sx={{
          borderRadius: '0 0 12px 12px',
          zIndex: 1000,
          margin: 0,
          position: 'relative',
          width: '100%',
          backgroundColor: Constants.WHITE,
          padding: '1rem',
        }}
      >
        <Grid
          container
          item
          xs={12}
          spacing={2}
        >
          <Grid item container xs={6}>
            <Grid item container direction="column" spacing={3}>
              <Grid item>
                <Typography variant="body1" fontSize="1.25rem" fontWeight="bold" textAlign="center">
                  {formatter.format(overallPayout < 0 ? 0 : overallPayout)}
                </Typography>
                <Typography variant="body1" fontSize="0.875rem" textAlign="center">
                  Projected Claims
                </Typography>
              </Grid>
              <Grid item>
                <Typography marginTop="1.3rem" variant="body1" fontSize="1.25rem" fontWeight="bold" textAlign="center">
                  {formatter.format(specificPayout)}
                </Typography>
                <Typography variant="body1" fontSize="0.875rem" textAlign="center">
                  {getSeperateBenefitName(specificBenefit)}
                </Typography>
                <Typography variant="body1" fontSize="0.875rem" textAlign="center">
                  Projected Claims
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={6}>
            <Grid item container direction="column" spacing={3}>
              <Grid item>
                <Typography variant="body1" fontSize="1.25rem" fontWeight="bold" textAlign="center">
                  {intFormatter.format(overallPeople < 0 ? 0 : overallPeople)}
                </Typography>
                <Typography variant="body1" fontSize="0.875rem" textAlign="center">
                  Projected Number of Claims
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" fontSize="1.25rem" fontWeight="bold" textAlign="center">
                  {intFormatter.format(specificPeople)}
                </Typography>
                <Typography variant="body1" fontSize="0.875rem" textAlign="center">
                  {getSeperateBenefitName(specificBenefit)}
                </Typography>
                <Typography variant="body1" fontSize="0.875rem" textAlign="center">
                  Projected Number of Claims
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}
