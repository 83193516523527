import store from 'app/store';
import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { ssoLogin } from 'features/login/state/service';

export default function SSO() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('otk');
  const navigate = useNavigate();

  const validateSso = async (otk) => {
    await store.dispatch(ssoLogin(otk)).unwrap();
    navigate('/login');
  };

  useEffect(() => {
    if (token) {
      validateSso(token);
    }
  }, [token]);

  return (
    <>
    </>
  );
}
