const getSeperateBenefitName = (specificBenefit) => {
  const benefitName = specificBenefit?.displayName;
  if (benefitName?.includes('Wellness')) {
    return 'Wellness';
  }
  if (benefitName?.includes('Health Screening')) {
    return 'Health Screening';
  }
  return benefitName;
};

export default getSeperateBenefitName;
