import html2canvas from 'html2canvas';

const setBoxShadowNone = (element) => {
  // eslint-disable-next-line no-param-reassign
  element.style.boxShadow = 'none';
  for (let i = 0; i < element.children.length; i += 1) {
    setBoxShadowNone(element.children[i]);
  }
};

const handleExport = (compRef, fileName) => {
  if (compRef.current) {
    const clone = compRef.current.cloneNode(true);
    setBoxShadowNone(clone);
    document.body.appendChild(clone);
    html2canvas(clone).then((canvas) => {
      document.body.removeChild(clone);
      const dataUrl = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.href = dataUrl;
      downloadLink.download = `${fileName}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  }
};

export default handleExport;
