import React from 'react';
import { Sector } from 'recharts';
import { intFormatter } from 'common/Constants';

const renderActiveShape = (props) => {
  const {
    cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill,
    payload, noText, category, COLORS,
  } = props;
  const textAnchor = 'start';

  let filledColor = null;
  let pos = null;
  let ex = null;
  let ey = null;

  if (payload[category] in COLORS) {
    filledColor = COLORS[payload[category]].COLOR;
    pos = COLORS[payload[category]].POS;
    ex = cx + 230;
    ey = cy + (pos - 2) * 80;
  }

  return (
    filledColor
      ? (
        <g>
          <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
            {payload.total}
          </text>
          <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={filledColor}
          />
          <Sector
            cx={cx}
            cy={cy}
            startAngle={startAngle}
            endAngle={endAngle}
            innerRadius={outerRadius + 6}
            outerRadius={outerRadius + 10}
            fill={filledColor}
          />
          {noText ? null : (
            <>
              <circle cx={ex - 9} cy={ey - 15} r={5} fill={filledColor} stroke="none" />
              <text x={ex} y={ey} dy={-9} textAnchor={textAnchor} fill="#333">
                {`${payload[category].slice(0, 1).toUpperCase() + payload[category].slice(1).toLowerCase()} ${intFormatter.format(payload.countOfCensus)}`}
              </text>
              <text x={ex} y={ey} dy={9} textAnchor={textAnchor} fill="#333">
                {`Rate ${(payload.percentageOfCensus)?.toFixed(2)}%`}
              </text>
              <text x={ex} y={ey} dy={27} textAnchor={textAnchor} fill="#999">
                {`(Benchmark ${(payload.percentageOfBenchmark)?.toFixed(2)}%)`}
              </text>
            </>
          )}

        </g>
      ) : <> </>
  );
};

export default renderActiveShape;
