import { applyMiddleware } from 'redux';
import { reduxSoldierMiddleware } from 'redux-soldier';
import { configureStore } from '@reduxjs/toolkit';
import scenarioReducer from 'features/scenario/state/reducer';
import authReducer from 'features/login/state/reducer';
import navigatorReducer from 'features/navigation/state/reducer';
import userSliceReducer from 'features/user/state/reducer';
import appReducer from './state/reducer';

export default configureStore({
  reducer: {
    scenario: scenarioReducer,
    auth: authReducer,
    navigator: navigatorReducer,
    user: userSliceReducer,
    app: appReducer,
  },
}, applyMiddleware(reduxSoldierMiddleware));
