/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import * as Constants from 'common/Constants';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Typography,
} from '@mui/material';
import { Warning } from '@mui/icons-material';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 1,
  borderRadius: 2,
  borderColor: '#000000',
  borderStyle: 'solid',
  backgroundColor: '#f0f1f2',
  color: '#000000',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: Constants.PRIMARY_DARK,
};

const acceptStyle = {
  borderColor: Constants.PRIMARY,
};

const rejectStyle = {
  borderColor: Constants.PRIMARY_WARNING,
};

const MaxSizeMB = 4;

function StyledDropzone({
  onChange,
  error,
  ...fields
}) {
  const [openErrorDialog, setErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    ...fields,
    maxFiles: 1,
    maxSize: MaxSizeMB * 1024 * 1024,

    onDrop: (files) => {
      const file = files[0];
      if (!file.name.endsWith('.csv')) {
        setErrorMessage(`File ${file.name} is not using the correct file format.`);
        files.pop();
        setErrorDialog(true);
      }
    },
  });

  const files = acceptedFiles.map((file) => (
    <Typography key={file.path} variant="body1" sx={{ fontSize: '1rem' }}>
      {` ${file.path}`}
    </Typography>
  ));

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
  }), [
    isFocused,
    isDragAccept,
    isDragReject,
  ]);

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(acceptedFiles);
    }
  }, [acceptedFiles, onChange]);

  let detailedMessage = '';
  if (error) {
    detailedMessage = error.message;
  } else if (files && files.length > 0) {
    detailedMessage = files;
  } else {
    detailedMessage = <>No file selected</>;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div {...getRootProps({ style })}>
            <input {...getInputProps({ onChange })} />
            {files && files.length > 0
              ? <p>Drag and drop, or click to replace the existing file</p>
              : <p>Drag file here or click to browse and select file</p>}
          </div>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item>
              <Typography variant="h5" sx={{ fontSize: '1rem' }}>
                Selected file:
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" color={error ? Constants.PRIMARY_WARNING : null} sx={{ fontSize: '1rem' }}>
                {detailedMessage}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog open={openErrorDialog} onClose={() => setErrorDialog(false)}>
        <DialogTitle sx={{ fontSize: '1.25rem', color: '#002E74' }}>
          <Warning sx={{ marginBottom: '-5px', fontSize: '1.7rem' }} />
          &nbsp;
          Incorrect file format
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ fontSize: '1rem' }}>
          <Typography>{errorMessage}</Typography>
          <Typography>
            <br />
            Update your file and try again. You can also&nbsp;
            <a href="/census_sample.csv" style={{ color: '#002E74' }}>download a template</a>
            .
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setErrorDialog(false)} variant="contained">Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default StyledDropzone;
