import {
  Grid, Button, Card, Box, Typography,
} from '@mui/material';
import RoutePath from 'app/router/RoutePath';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  COOKIE_JWT, CARRIER_CODE, MAIN_HOST, AFLAC_SMAL,
  BST_CARRIER, CLIENT_PORTAL_URL,
} from 'common/AppConstant';
import ceresByMPLogo from 'asset/ceres-logo.png';
import 'styles.css';
import { AFLAC } from 'common/Constants';

export default function Login() {
  const jwt = useSelector((state) => state.auth.jwt);
  const carrierCode = useSelector((state) => state.auth.carrierCode) || BST_CARRIER;
  /* eslint-disable-next-line prefer-destructuring */
  const pathname = window.location.pathname;
  const isAflacUrl = [AFLAC.PATHNAME].includes(pathname);

  const navigate = useNavigate();

  const navigateTo = (path) => {
    navigate(path);
  };

  const handleAflacSso = () => {
    window.location.replace(`${MAIN_HOST}/saml/login?idp=${AFLAC_SMAL}`);
  };

  const navigateToClientPortal = () => {
    window.location.replace(CLIENT_PORTAL_URL);
  };

  useEffect(() => {
    if (jwt) {
      navigateTo(RoutePath.app.scenario.fullpath);
      localStorage.setItem(COOKIE_JWT, jwt);
    } else if (!isAflacUrl) {
      navigateToClientPortal();
    }
  }, [jwt, navigateTo]);

  useEffect(() => {
    const savedJwt = localStorage.getItem(COOKIE_JWT);
    if (savedJwt && savedJwt !== 'null') {
      navigateTo(RoutePath.app.scenario.fullpath);
    }
  }, [navigateTo]);

  useEffect(() => {
    if (carrierCode) {
      localStorage.setItem(CARRIER_CODE, carrierCode);
    }
  }, [carrierCode]);

  return (
    isAflacUrl && (
    <Box
      margin={-1}
      sx={{
        minHeight: '100vh',
        width: 'auto',
        backgroundColor: '#EEF0F3',
      }}
    >
      <Grid container padding={2}>
        <Grid item xs={4.5} />
        <Grid item xs={3} marginTop={isAflacUrl ? 11 : 1}>
          <Card sx={{ borderRadius: '1rem', width: '370px', paddingBottom: '24px' }}>
            <Grid
              container
              spacing={2}
              direction="column"
            >
              <Grid item xs={12}>
                <img
                  src={ceresByMPLogo}
                  alt="Ceres By MultiPlan"
                  height="auto"
                  width="155"
                  style={{ display: 'block', margin: '0 auto' }}
                />
              </Grid>
              <Grid item xs={12} marginBottom={2}>
                <Button variant="outlined" fullWidth onClick={() => handleAflacSso()}> Continue with Aflac SSO </Button>
              </Grid>
            </Grid>
          </Card>
          <Box
            component="footer"
            sx={{
              paddingTop: '35px',
              color: '#353B43',
              marginTop: '325px',
              marginBottom: '0.25rem',
            }}
          >
            <Grid
              container
              spacing={32}
              direction="column"
              lineHeight="1.5"
              sx={{
                paddingLeft: '90px',
                width: 'calc(100% + 400px)',
                overflow: 'hidden',
              }}
            >
              <Typography fontSize="0.75rem">
                MultiPlan Inc. All Rights Reserved
                <br />
                CPT © 2023 American Medical Association. All Rights Reserved.
                <br />
                Fee schedules, relative value units, conversion factors and/or related
                components are not assigned by the AMA, are not part of CPT, and the AMA is
                not recommending their use. The AMA does not directly or indirectly practice
                medicine or dispense medical services. The AMA assumes no liability for data
                contained or not contained herein.
                <br />
                CPT is a registered trademark of the American Medical Association
                <br />
                <br />
                <p>
                  U.S. Government Rights
                  <br />
                  This product includes CPT and/or CPT® Changes which are commercial technical
                  data, which was developed exclusively at private expense by the American
                  Medical Association, 330 North Wabash Avenue, Chicago, Illinois 60611. The
                  American Medical Association does not agree to license CPT to the Federal
                  Government based on the license in FAR 52.227-14 (Data Rights - General) and
                  DFARS 252.227-7015 (Technical Data - Commercial Items) or any other license
                  provision. The American Medical Association reserves all rights to approve
                  any license with any Federal agency.
                </p>
              </Typography>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
    )
  );
}
