import React from 'react';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Typography } from '@mui/material';
import { POLICY_MODULE_TYPE } from 'common/Constants';
import { StyledTableCell } from './styledTableComponent';

export default function PolicyTableHead({ type, isCriticalIllness = false }) {
  let headTitleArray = ['Name', 'Benefit', 'Claims', 'Projected Claims'];
  if (!isCriticalIllness) {
    headTitleArray = ['Name', 'Benefit 1', 'Benefit 2', 'Claims', 'Projected Claims'];
  }
  if (type === POLICY_MODULE_TYPE.PERIOD) {
    headTitleArray = ['Name', 'Benefit', 'Benefit Period', 'Claims', 'Projected Claims'];
    if (!isCriticalIllness) {
      headTitleArray = ['Name', 'Benefit 1', 'Benefit 2', 'Benefit Period', 'Claims', 'Projected Claims'];
    }
  } else if (type === POLICY_MODULE_TYPE.FRACT) {
    headTitleArray = ['Name', 'Closed', 'Open', 'Claims', 'Projected Claims'];
    if (!isCriticalIllness) {
      headTitleArray = ['Name', 'Benefit 1 Closed', 'Benefit 1 Open', 'Benefit 2 Closed', 'Benefit 2 Open', 'Claims', 'Projected Claims'];
    }
  }
  return (
    <TableHead>
      <TableRow>
        <StyledTableCell padding="checkbox" />
        {headTitleArray.map((title) => (
          <StyledTableCell align={title === 'Projected Claims' ? 'right' : 'left'}>
            {' '}
            <Typography fontWeight="500">{title}</Typography>
            {' '}
          </StyledTableCell>
        ))}

      </TableRow>
    </TableHead>
  );
}
