import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { MAIN_HOST } from 'common/AppConstant';

export const getLastLoginDate = createAsyncThunk(
  'user/login/last',
  async () => {
    const response = await axios.get(`${MAIN_HOST}/user/login/last`);
    return response.data;
  },
);

export const getActivities = createAsyncThunk(
  'report/activaty',
  async () => {
    const response = await axios.get(`${MAIN_HOST}/report/activaty`);
    return response.data;
  },
);

export const acceptTerms = createAsyncThunk(
  'user/terms-accepted',
  async () => {
    const response = await axios.post(`${MAIN_HOST}/user/terms-accepted`);
    return response.data;
  },
);

export const getEula = createAsyncThunk(
  'user/eula',
  async () => {
    const response = await axios.get(`${MAIN_HOST}/user/eula`);
    return response.data;
  },
);

export const getDisclaimer = createAsyncThunk(
  'disclaimer',
  async () => {
    const response = await axios.get(`${MAIN_HOST}/disclaimer`);
    return response.data;
  },
);

export default getLastLoginDate;
