const nonNegativeIntegerRegex = /^\d+$/;
const positiveIntegerRegex = /^[1-9]\d*$/;

export const validateWholePercentage = (value) => {
  if (value === '' || value === null) {
    return true;
  }
  const numberValue = Number(value);
  return (nonNegativeIntegerRegex.test(value) && numberValue >= 0 && numberValue <= 100) || 'Must be between 0-100 and a whole number';
};

export const validateNonNegativeInteger = (value) => {
  if (value === '' || value === null) {
    return true;
  }
  return nonNegativeIntegerRegex.test(value) || 'Must be greater than or equal to 0 and a whole number';
};

export const validatePositiveInteger = (value) => {
  if (value === '' || value === null) {
    return true;
  }
  return positiveIntegerRegex.test(value) || 'Headcount must be greater than 0 and a whole number';
};
