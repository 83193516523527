import { createSlice } from '@reduxjs/toolkit';
import * as AppConstants from 'common/AppConstant';
import moment from 'moment';
import * as API from './service';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    lastLoginDate: 'N/A',
    termsAccepted: false,
    eula: '',
    disclaimer: '',
  },
  extraReducers: (builder) => {
    builder.addCase(API.getLastLoginDate.fulfilled, (state, action) => {
      if (action.payload) {
        state.lastLoginDate = moment(action.payload).format(AppConstants.FORMAT_DATE);
      }
    });
    builder.addCase(API.acceptTerms.fulfilled, (state, action) => {
      if (action.payload) {
        state.termsAccepted = action.payload.termsAccepted;
      }
    });
    builder.addCase(API.getEula.fulfilled, (state, action) => {
      if (action.payload) {
        state.eula = action.payload;
      }
    });
    builder.addCase(API.getDisclaimer.fulfilled, (state, action) => {
      if (action.payload) {
        state.disclaimer = action.payload;
      }
    });
  },
});

export default userSlice.reducer;
