/* eslint-disable react/destructuring-assignment */
export default function VALIDATION_DUPLICATION(
  existingRecord,
  targetId,
  DUPLICATION_ERROR_MSG = 'Existing record is found.',
  targetProperty = 'id',
) {
  if (
    existingRecord
    && existingRecord.data
    && targetId > 0
    && targetId === existingRecord.data.id
  ) {
    return null;
  }
  return existingRecord.data && existingRecord.data[targetProperty]
    ? DUPLICATION_ERROR_MSG
    : null;
}
