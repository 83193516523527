import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  BrowserRouter,
} from 'react-router-dom';
import axios from 'axios';
import { COOKIE_JWT, MAIN_HOST } from 'common/AppConstant';
import { Provider } from 'react-redux';
import { updateSystemErrorMsg, increaseFetchingQueue, decreaseFetchingQueue } from 'app/state/reducer';
import * as ErrorConstants from 'common/ErrorConstants';
import { updatePasswordChangeStatus } from 'features/login/state/reducer';
import { TOKEN_REFRESH_URL } from 'features/login/state/service';
import { DOMAIN_PREFIX_REGEX } from 'common/Constants';
import App from './App';
import store from './app/store';

const pattern = new RegExp(`^${MAIN_HOST}/scenario/status/id/\\d+$`);
const mapAxiosErrorToText = (axiosError) => {
  const UNKNOWN_ERROR = 'An unknown error occured.';

  if (axiosError && axiosError.response) {
    if (axiosError.response.data) {
      const { errorCode } = axiosError.response.data;
      switch (errorCode) {
        case ErrorConstants.ERROR_CODE.DUPLICATED_RECORD:
          return 'Duplicated record is found.';
        case ErrorConstants.ERROR_CODE.VALIDATION_ERROR:
          return `Invalid content: ${axiosError.response.data.message}`;
        default:
      }
    }

    switch (axiosError.response.status) {
      case 400:
        return 'The request was not valid. Please verify the data and try again.';
      case 401:
      case 403:
        return 'You do not have permission for that request.';
      case 500:
        return 'There was an internal server error. Please try again later.';
      default:
        return UNKNOWN_ERROR;
    }
  }

  return UNKNOWN_ERROR;
};

axios.interceptors.request.use(
  (config) => {
    const NO_AUTH_REQD_URLS = [
      new RegExp(`${DOMAIN_PREFIX_REGEX.source}(/auth/login)$`),
      new RegExp(`${DOMAIN_PREFIX_REGEX.source}/sso\\?otk=[\\w-]+$`),
      new RegExp(`${DOMAIN_PREFIX_REGEX.source}/auth/jwtprovider\\?oneTimeKey=[\\w-]+$`),
    ];
    // exclude the the behind the scense even
    // for now harding the event here
    if (config.url !== TOKEN_REFRESH_URL && !pattern.test(config.url)) {
      store.dispatch(increaseFetchingQueue());
    }

    if (!localStorage.getItem(COOKIE_JWT)
      && !NO_AUTH_REQD_URLS.some((p) => p.test(config.url))) {
      return Promise.reject(new Error('JWT is null'));
    }

    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${localStorage.getItem(COOKIE_JWT)}`;

    return config;
  },
  (error) => Promise.reject(error),
);

axios.interceptors.response.use(
  (response) => {
    if (response?.request?.responseURL !== TOKEN_REFRESH_URL
      && !pattern.test(response?.request?.responseURL)) {
      store.dispatch(decreaseFetchingQueue());
    }

    return response;
  },
  (error) => {
    if (error?.config?.url !== TOKEN_REFRESH_URL) {
      store.dispatch(decreaseFetchingQueue());
    }

    if (error.response.data.errorCode === 'REQUIRE_PASSWORD_CHANGE') {
      store.dispatch(updatePasswordChangeStatus(true));
      return Promise.reject(error);
    }
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }

    store.dispatch(updateSystemErrorMsg(mapAxiosErrorToText(error)));
    return Promise.reject(error);
  },
);

const root = createRoot(
  document.getElementById('root'),
);

root.render(
  <BrowserRouter>

    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
);
